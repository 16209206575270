import React, { useContext, useState, useEffect, useMemo } from "react";

import {
  Button,
  Column,
  DataServiceContext,
  Field,
  FieldTypes,
  Panel,
  Row,
  Toolbar,
  useModalDialog,
} from "athena-next-ui-lib";
import styles from "/pages/auth/report-settings.module.scss";
import { faCog } from "@fortawesome/free-solid-svg-icons";

const refractoryPeriodList = [
  { label: "10 minutes", value: "tenMin" },
  { label: "1 day", value: "oneDay" },
  { label: "1 week", value: "oneWeek" },
];

const sensitivityList = [
  { label: "high", value: "high" },
  { label: "medium", value: "medium" },
  { label: "low", value: "low" },
];

const significanceList = [
  { label: "high", value: "high" },
  { label: "medium", value: "medium" },
  { label: "low", value: "low" },
];

const timeRangeList = [
  {
    label: "Today",
    value: "today",
  },
  {
    label: "Last 24 Hours",
    value: "last-24-hours",
  },
  {
    label: "Last 7 Days",
    value: "last-7-days",
  },
  {
    label: "Week to Date",
    value: "week-to-date",
  },
  {
    label: "Last Week",
    value: "last-week",
  },
  {
    label: "Month to Date",
    value: "month-to-date",
  },
  {
    label: "Last Month",
    value: "last-month",
  },
  {
    label: "All Time",
    value: "all-time",
  },
];

const listData = {
  incident_sensitivity: sensitivityList,
  refractory_period: refractoryPeriodList,
  rc_significance: significanceList,
  rc_time_range: timeRangeList,
};

const buttonGroupList = {
  k8s_mute: [
    { label: "Don't Create", value: true },
    { label: "Create", value: false },
  ],
};

export const RcAcctSetting = (props) => {
  const {
    title,
    desc,
    field: fieldName,
    default: defaultValue,
    fieldType,
    numberOfColumns,
    accountSettingsDefaults,
  } = props;

  const dataServiceContext = useContext(DataServiceContext);
  const { openConfirmationDialog, closeModalDialog } = useModalDialog();

  const [processing, setProcessing] = useState(false);
  const [valueModified, setValueModified] = useState(false);

  const [accountSettingData, setAccountSettingData] = useState({
    ...props.origAccountSettingData,
  }); //stored values
  const [origAccountSettingData, setOrigAccountSettingData] = useState({
    ...props.origAccountSettingData,
  }); //user changes

  const [isDiffFromDefault, setDiffFromDefault] = useState(false);
  const [undoClicked, setUndoClicked ] = useState(false);

  const panelCSS = isDiffFromDefault ? "highlight" : "";
  const width = "calc(" + Math.round(100 / numberOfColumns) + "% - "+Math.round(80/numberOfColumns)+"px)";

  useEffect(() => {
    if (
      accountSettingData?.[fieldName] == undefined ||
      origAccountSettingData?.[fieldName] == undefined
    ) {
      //skip if value not been set
      return;
    }
    if (
      accountSettingData?.[fieldName] !== origAccountSettingData?.[fieldName]
    ) {
      setValueModified(true);
    } else {
      setValueModified(false);
    }

    if (defaultValue && origAccountSettingData?.[fieldName] !== defaultValue) {
      //if default value is provided in the structure
      setDiffFromDefault(true);
    } else if (
      origAccountSettingData?.[fieldName] &&
      origAccountSettingData?.[fieldName] !==
        accountSettingsDefaults?.[fieldName]
    ) {
      setDiffFromDefault(true);
    } else {
      setDiffFromDefault(false);
    }
  }, [accountSettingData, origAccountSettingData, accountSettingsDefaults]);

  const getField = (fieldValue) => {
    let field = null;
    switch (fieldType) {
      case "button-group":
        const btnList = buttonGroupList[fieldName];
        const btnValue = btnList.find((item) => item.value === fieldValue);
        field = (
          <Field
            type={fieldType}
            name={fieldName}
            css={["md", "dark"]}
            values={btnList}
            value={btnValue}
            onUpdateValue={(selObj) => {
              if (selObj.value !== btnValue.value) {
                setAccountSettingData({
                  ...accountSettingData,
                  fieldName: selObj.value,
                });
              }
            }}
          />
        );
        break;
      case FieldTypes.SINGLE_SELECT:
        const matchingValueArr = listData[fieldName].filter(
          (itm) => itm.value === fieldValue
        );
        field = (
          <Field
            type={fieldType}
            value={matchingValueArr}
            values={listData[fieldName]}
            response={{ code: 200 }}
            name={fieldName}
            onUpdateValue={(value) => {
              if (value?.length < 1) return;

              //extract value
              const fldValue = value?.[0]?.value;

              if (fldValue !== accountSettingData?.[fieldName]) {
                setAccountSettingData({
                  ...accountSettingData,
                  [fieldName]: fldValue,
                });
              }
            }}
          />
        );
        break;
    }
    return field;
  };

  const field = useMemo(() => {
    const acctData = props.origAccountSettingData;
    const fieldValue = acctData[fieldName];
    setAccountSettingData({ ...props.origAccountSettingData });
    setOrigAccountSettingData({ ...props.origAccountSettingData });
    undoClicked && setUndoClicked(false);

    return getField(fieldValue);
  }, [props.origAccountSettingData, undoClicked]);

  const applyHandler = () => {
    openConfirmationDialog({
      title: "Apply Change",
      icon: faCog,
      closeOnSubmit: false,
      submit: () => updateAccountSettings(accountSettingData[fieldName]),
      content: (
        <Column>
          <span>Are you sure you want to change the setting:</span>
          <span>
            <b>{title}</b>?
          </span>{" "}
        </Column>
      ),
    });
  };

  const undoHandler = () => {
    setUndoClicked(true);
    return setAccountSettingData({
      ...accountSettingData,
      [fieldName]: origAccountSettingData?.[fieldName],
    });
  };

  const resetToDefault = () => {
    const newValue = defaultValue || accountSettingsDefaults?.[fieldName];
    return updateAccountSettings(newValue);
  };

  const updateAccountSettings = (newValue) => {
    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() =>
        dataServiceContext.fetch("accountsetting/create", {
          [fieldName]: newValue,
        })
      )
      .then((response) => {
        if (response.response.code === 200) {
          const accountSettingData = (response.data || [])[0];
          setAccountSettingData({ ...accountSettingData });
          setOrigAccountSettingData({ ...accountSettingData });
          props.setOrigAccountSettingData({ ...accountSettingData }); //setting on the parent
          return true;
        } else {
          const errMessage = `Unable to set ${title}.  ${response.response.message}`;
          throw new Error(errMessage);
        }
      })
      .then((successFlag) => {
        if (successFlag) {
          closeModalDialog();
        }
        return successFlag;
      })
      .finally(() => setProcessing(false));
  };

  return (
    <>
      <Panel width={width} height={"auto"} style={panelCSS}>
        <Toolbar title={title}>
          {isDiffFromDefault && (
            <Button
              type={"secondary"}
              size={"sm"}
              disabled={processing}
              processing={processing}
              onClick={resetToDefault}
            >
              Restore Default
            </Button>
          )}
        </Toolbar>

        <Row>
          <Column width={"400px"}>
            <div className={styles.fieldRow}>
              {field}
              {valueModified && (
                <div style={{ marginLeft: "8px" }}>
                  <Button
                    type={"primary"}
                    processing={processing}
                    disabled={processing}
                    onClick={applyHandler}
                  >
                    Apply
                  </Button>
                </div>
              )}
              {valueModified && (
                <div style={{ marginLeft: "8px" }}>
                  {" "}
                  <Button
                    type={"secondary"}
                    disabled={processing}
                    onClick={undoHandler}
                  >
                    Undo
                  </Button>
                </div>
              )}
            </div>
          </Column>
        </Row>

        <Row>
          <div className={styles.explanation}>{desc}</div>
        </Row>
      </Panel>
    </>
  );
};

RcAcctSetting.getInitialProps = async (ctx) => {
  return {};
};

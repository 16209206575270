import {
  commonSection,
  outEnabled,
} from "./CommonFields";
import { rebuildSection } from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";
//--------------------------------------------------------------
// pixie
//--------------------------------------------------------------
export const pixie = {
  common: rebuildSection(commonSection, {
    integration: "pixie",
    category: IntegrationCategories.TELEMETRY,
    out_event_type: "zebrium_incident,zebrium_monitor_metrics",
    auth_scheme: "basic",
    in_region: "US",
  }),
  detection: [
    outEnabled,
    {
      field_name: "tabTitle",
      field_type: "description",
      value: "Collects service mapping information from Pixie.",
    },
    {
      field_name: "accountId2",
      required: true,
      label: "Cluster Id",
      db_field_name: "auth_username",
      field_type: "text",
      tooltip: "The cluster ID can be seen when you create an API key.",
    },
    {
      field_name: "apiKey2",
      required: true,
      label: "API Key",
      db_field_name: "auth_password",
      field_type: "text",
      tooltip:
        "Create an API key under API Keys in the Settings menu in Pixie.",
    },
  ],
};

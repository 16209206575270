import React, {
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  createContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import styles from "./WizardDialog.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faExclamationTriangle,
} from "@fortawesome/pro-regular-svg-icons";
import { ThirdPartyLogo } from "athena-next-ui-lib";

const WizardDialogContext = createContext();

export const WizardDialogContextProvider = (props) => {
  const [visible, setVisible] = useState(false);
  const [dialogSettings, setDialogSettings] = useState();

  const openWizardDialog = useCallback(
    (settings) => {
      setVisible(true);
      setDialogSettings(settings);
    },
    [setVisible]
  );

  const closeWizardDialog = useCallback(() => {
    setVisible(false);
    setDialogSettings(null);
  }, [setVisible]);

  const contextValueParams = useMemo(
    () => ({ openWizardDialog, closeWizardDialog }),
    []
  );

  return (
    <WizardDialogContext.Provider value={contextValueParams} {...props}>
      {props.children}
      {visible && (
        <WizardDialog
          settings={dialogSettings}
          openWizardDialog={openWizardDialog}
          closeWizardDialog={closeWizardDialog}
        />
      )}
    </WizardDialogContext.Provider>
  );
};

export const useWizardDialog = () => {
  const context = useContext(WizardDialogContext);
  if (context === undefined) {
    throw new Error(
      "useWizardDialog must be defined within WizardDialogContextProvider"
    );
  }
  return context;
};

export const WizardDialog = (props) => {
  const [wizard, setWizard] = useState(null);

  const close = () => {
    if (props.close) {
      props.close();
    } else {
      props.closeWizardDialog();
    }
  };

  useEffect(() => {
    const w = (
      <div className={styles.wizardOuter}>
        <div className={styles.wizardInner}>
          <div className={styles.wizardCloseIcon} onClick={() => close()}>
            <FontAwesomeIcon icon={faTimes} size={"lg"} />
          </div>
          {props.settings.content}
        </div>
      </div>
    );

    setWizard(w);
  }, [props.settings]);

  return wizard;
};

export function WizardTitlebar(props) {
  return (
    <div className={styles.wizardTitlebar}>
      <div className={styles.wizardIcon}>
        <ThirdPartyLogo type={props.icon.logo} width={"80px"} theme={"dark"} />
        {/* <img src={`../../images/logos/${props.icon.filename}`}  {...props.icon.size}/> */}
      </div>
      <div>
        <div className={styles.wizardTitle}>{props.title}</div>
        <div className={styles.wizardBar}>{props.children}</div>
      </div>
    </div>
  );
}

export function WizardContent(props) {
  return (
    <div
      className={`${styles.wizardContent} ${props.css}`}
      style={props.styles}
    >
      {props.children}
    </div>
  );
}

export const WizardTabset = forwardRef((props, ref) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [tabs, setTabs] = useState(null);

  const [content, setContent] = useState([]);

  useImperativeHandle(ref, () => ({
    getSelectedTabType,
    setSelectedTabByIndex,
  }));

  const getSelectedTabType = () => {
    const selTab = content[selectedTabIndex];
    return selTab?.props?.type;
  };

  const selectTab = (index) => {
    setSelectedTabIndex(index);
    props?.selectTabCallback(index);
  };

  const setSelectedTabByIndex = (index) => {
    if (index > -1) {
      return selectTab(index);
    } else {
      return selectTab(0);
    }
  };

  useEffect(() => {
    if (props.children) {
      const children = Array.isArray(props.children)
        ? props.children
        : [props.children];
      setContent(children);
    }
  }, [props.children]);

  useEffect(() => {
    let _tabData = [];
    const allTabs = content.map((t, index) => {
      let css =
        index === selectedTabIndex
          ? `${styles.wizardTab} ${styles.wizardTabSelected}`
          : styles.wizardTab;
      if ((t.props?.title || "").length < 1) {
        css = "";
      }
      _tabData.push(t.props.type);
      css = t?.props?.errors === true ? `${css} ${styles.errors}` : css;
      const icon =
        t?.props?.errors === true ? (
          <FontAwesomeIcon icon={faExclamationTriangle} />
        ) : null;
      return (
        <div
          className={css}
          key={t?.props?.type}
          onClick={() => selectTab(index)}
        >
          <div className={styles.label}>
            {icon && <div className={styles.icon}>{icon}</div>}
            <div className={styles.labels}>{t?.props?.title}</div>
          </div>
        </div>
      );
    });

    setTabs(allTabs);
  }, [content, selectedTabIndex]);

  return (
    <div className={styles.wizardTabsetContainer}>
      <div className={styles.wizardTabset}>{tabs}</div>
      {/*<div>{content[selectedTab]}</div>*/}
      <div>
        {content.map((c, index) => {
          const css =
            index !== selectedTabIndex
              ? { display: "none" }
              : { display: "block" };
          return (
            <div key={c?.props?.type} style={css}>
              {c}
            </div>
          );
        })}
      </div>
    </div>
  );
});

export function WizardTab(props) {
  if (props.hidden === true) {
    return null;
  } else {
    return (
      <WizardContent css={props.css} styles={props.styles}>
        {props.children}
      </WizardContent>
    );
  }
}

export function WizardButtonBar(props) {
  return <div className={styles.wizardButtonBar}>{props.children}</div>;
}

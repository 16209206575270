import React, {
  useContext,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Row,
  Field,
  DataServiceContext,
  useModalDialog,
} from "athena-next-ui-lib";
import { faEnvelope } from "@fortawesome/pro-light-svg-icons";

export const DialogForgotPassword = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog, closeModalDialog } =
    useModalDialog();

  const [processing, setProcessing] = useState(false);

  useImperativeHandle(ref, () => ({
    showDialog,
    sendResetPasswordLink,
  }));


  const sendResetPasswordLink = (userEmail) => {
    return (
      Promise.resolve()
        .then(() => setProcessing(true))
        .then(() =>
          dataServiceContext.fetch(
            "passwordreset/create/forgot",
            { user_name: userEmail },
            true
          )
        )
        //    .then((sendLinkCall)=>{
        //        const isLinkSent =  sendLinkCall.response.code === 200;
        //        const dialogTitle = isLinkSent ?  "Email sent" : "Unable to send email";
        //        const description = isLinkSent ?  "Link has been sent.  Please check your email." : sendLinkCall.response.message;

        //        const modalDialogSettings = {
        //            icon: faEnvelope,
        //            title: dialogTitle,
        //            processing:processing,
        //            submitLabel:"OK",
        //            type:"warn",
        //            onSubmit: closeModalDialog,
        //            closeOnSubmit:true,
        //            maxWidth: "450px",
        //            content:   <><Row>
        //                <Field type={"description"}
        //                       value={<span>{description}</span>}/>
        //            </Row>
        //            </>
        //        }
        //        openModalDialog(modalDialogSettings)
        //    })
        .finally(() => setProcessing(false))
    );
  };

  const showDialog = () => {
    const modalDialogSettings = {
      icon: faEnvelope,
      type: "warn",
      title: "Request Received",
      processing: processing,
      submitLabel: "OK",
      submit: closeModalDialog,
      closeOnSubmit: true,
      hideCloseIcon: true,
      content: (
        <>
          <Row>
            <Field
              type={"description"}
              value={<span>Check your email for further instructions.</span>}
            />
          </Row>
        </>
      ),
    };

    openModalDialog(modalDialogSettings);
  };

  return null;
});

import React, { useState, useEffect, useContext } from "react";

import { DataServiceContext, Field, FieldTypes } from "athena-next-ui-lib";
import { IntegrationFormContext } from "/components-biz";

export function ServiceGroupSelector(props) {
  const integrationContext = useContext(IntegrationFormContext);
  const dataServiceContext = useContext(DataServiceContext);
  const { serviceGroupsRef, selectedServiceGroups } = props;

  const [selectedValues, setSelectedValues] = useState([]);
  const [serviceGroups, setServiceGroups] = useState(null);

  const [response, setResponse] = useState({ code: 0 });

  useEffect(() => {
    if (
      serviceGroups &&
      serviceGroups?.length > 0 &&
      selectedServiceGroups?.length > 0
    ) {
      const selSvcGroupStrArr = selectedServiceGroups.split(",");
      const selSvcGroups = serviceGroups.filter(
        (sg) => selSvcGroupStrArr.indexOf(sg.value) > -1
      );
      setSelectedValues([...selSvcGroups]);
    }
  }, [serviceGroups, selectedServiceGroups]);

  const onUpdateValue = (selObjArr) => {
    if (!serviceGroups) return;

    if (Array.isArray(selObjArr) && props.onUpdateValue) {
      props.onUpdateValue(selObjArr);
    }
  };

  const defaultContentProps = {
    ...props,
    name: "service_group",
    label: "Service Groups",
    type: FieldTypes.MULTI_SELECT,
    labelWidth: props.labelWidth,
    fieldWidth: props.fieldWidth,
    truncateDisplayBeyond: 25,
    ref: serviceGroupsRef,
    value: selectedValues,
    values: serviceGroups || [],
    response: response,
    allowSelectAll: true,
    onUpdateValue: onUpdateValue,
    selectAllLabel: "Any",
  };

  const [content, setContent] = useState(<Field {...defaultContentProps} />);

  useEffect(() => {
    if (serviceGroups && response.code != 0) {
      setContent(<Field {...defaultContentProps} response={response} />);
    }
  }, [serviceGroups, response]);

  useEffect(() => {
    setContent(
      <Field
        {...defaultContentProps}
        value={selectedValues}
        response={response}
      />
    );
  }, [selectedValues]);

  useEffect(() => {
    if (integrationContext.serviceGroupsData) {
      setServiceGroups(integrationContext.serviceGroupsData);
      setResponse({ code: 200 });
    } else {
      loadServiceGroups();
    }
  }, []);

  const loadServiceGroups = () => {
    return dataServiceContext
      .fetch("incident/read/servicegroups", {
        time_from: 1,
        time_to: 99999999999,
      })
      .then((response) => {
        if (response.response.code == 200) {
          const svcGroupData = response.data || [];

          const svc_grps = [
            { name: "default", label: "Shared Services", value: "default" },
          ].concat(
            svcGroupData.map((itm) => {
              return { ...itm, value: itm.name, label: itm.name };
            })
          );
          setServiceGroups(svc_grps);
          integrationContext.setServiceGroupsData([...svc_grps]);
        }
        setResponse({ ...response?.response });
      });
  };

  return <>{content}</>;
}

import {
  detectionSectionTop,
  signalSectionBottom,
  commonSection,
  inEnabled,
  outEnabled,
} from "./CommonFields";
import { rebuildSection } from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";

//--------------------------------------------------------------
// pagerduty
//--------------------------------------------------------------
export const pagerduty = {
  common: rebuildSection(commonSection, {
    integration: "pagerduty",
    category: IntegrationCategories.INCIDENT_MANAGEMENT,
    out_enabled: true,
    out_alert_on_new_incidents: false,
    in_enabled: true,
  }),
  // signal: {
  //   top: [inEnabled],
  //   APIConfig: [
  //     {
  //       field_name: "username",
  //       required: true,
  //       label: "Username",
  //       db_field_name: "auth_username",
  //       field_type: "text",
  //     },
  //     {
  //       field_name: "apiKey",
  //       required: true,
  //       label: "API Key",
  //       db_field_name: "auth_password",
  //       field_type: "text",
  //     },
  //   ],
  //   bottom: signalSectionBottom,
  //   order: ["top", "APIConfig", "bottom"], //ordering of ui sections
  // },

  createIncident: {
    top: [outEnabled],
    webhookConfig: [
      {
        field_name: "accessToken2",
        required: true,
        label: "Integration Key",
        db_field_name: "auth_token",
        field_type: "text",
      },
      ...detectionSectionTop,
    ],
    order: ["top", "webhookConfig"], //ordering of ui sections
  },
};

import React, {
  useContext,
  useImperativeHandle,
  forwardRef,
} from "react";
import {
  Column,
  Row,
  Field,
  PageContext,
  useModalDialog,
} from "athena-next-ui-lib";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import {UserMgmtUtils} from "/_utilities";

export const DialogInviteUser = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const { openModalDialog, closeModalDialog } =
    useModalDialog();

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const getInviteLink = () => {
    const userObj = props.userObj || {};
    const pathPrefix = pageContext?.env?.INGRESS_PREFIX;
    //link is in the form of /auth/sign-in?--username---&--pwd--
    console.log("--- user/pwd:" + userObj.name + " / " + props.randPassword);
    const username = encodeURIComponent(
      UserMgmtUtils.encodeParam(userObj.name)
    );
    const pwd = encodeURIComponent(
      UserMgmtUtils.encodeParam(props.randPassword)
    );
    return (
      window.location.origin +
      pathPrefix +
      "/auth/sign-in?" +
      username +
      "&" +
      pwd
    );
  };

  const showDialog = () => {
    const userObj = props.userObj || {};
    const inviteContent = getInviteLink() + "\r\n";

    const modalDialogSettings = {
      icon: faUserPlus,
      title: "Invite User",
      processing: false,
      submitLabel: "OK",
      onSubmit: closeModalDialog,
      content: (
        <>
          <Row>
            <Field
              type={"description"}
              value={
                <span>
                  You are inviting <b>{userObj.name}</b>. Please copy and send
                  this user the invite link below:
                </span>
              }
            />
          </Row>

          <Row>
            <Column>
              <Field
                type="copy"
                value={inviteContent}
                height={80}
                fieldWidth={"620px"}
              />
            </Column>
          </Row>
        </>
      ),
    };

    return (
      Promise.resolve()
        //  .then(()=>!groups && loadGroups())
        .then(() => openModalDialog(modalDialogSettings))
    );
  };

  return null;
});

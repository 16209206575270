import React, {
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Form,
  Row,
  Field,
  DataServiceContext,
  useModalDialog,
  FieldTypes,
  getErrorMessageDisplay,
} from "athena-next-ui-lib";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AccessTokenDeploymentSelector } from "./AccessTokenDeploymentSelector";
import { TokenGen } from "./TokenGen";

export const DialogAccessToken = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();

  const processing = false;

  const FormRef = useRef();
  const name = useRef();
  const role = useRef();
  const deploymentSelectorRef = useRef();
  const tokenGenRef = useRef();
  const disableRef = useRef();

  const tokenEnableList = [
    { label: "Enabled", value: false },
    { label: "Disabled", value: true },
  ];

  const roleList = [
    // {label: "Owner", value: "owner"},
    { label: "Admin", value: "admin" },
    { label: "Editor", value: "editor" },
    { label: "Viewer", value: "viewer" },
  ];

  const fieldProps = {
    labelWidth: "115px",
    fieldWidth: "250px",
  };

  const isAdd = props.action === "ADD";

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const regenCallback = (atObj) => {
    return Promise.resolve()
      .then(() => showDialog(atObj))
      .then(() => props.updateCallback());
  };

  const showDialog = (atObj) => {
    const title = isAdd ? "Add Access Token" : "Edit Access Token";
    const buttonLabel = isAdd ? "Add" : "Save";

    const currentObj = isAdd ? {} : { ...atObj };
    const selectedRole = isAdd
      ? [{ label: "Viewer", value: "viewer" }]
      : roleList.filter((rl) => rl.value === currentObj.role);

    const selectedDeployment = isAdd ? "" : atObj.deployment_id;

    const tokenEnabled = isAdd
      ? tokenEnableList[0]
      : tokenEnableList.find((itm) => itm.value === atObj.disable); //default to enabled

    const addSaveHandler = () => {
      let payload = FormRef.current.getValues();

      if (payload.errors.length > 0) {
        //form contains error(s), throw error
        const err = { message: getErrorMessageDisplay(payload.errors) };
        throw err;
      }

      const apiUrl = "accesstoken/create";

      return Promise.resolve()
        .then(() => dataServiceContext.fetch(apiUrl, { ...atObj, ...payload }))
        .then((atAddSaveCall) => {
          if (atAddSaveCall.response.code === 200) {
            props?.updateCallback?.();
          } else {
            throw atAddSaveCall.response;
          }
          return true;
        });
    };

    const modalDialogSettings = {
      title: title,
      icon: null,
      submitLabel: buttonLabel,
      submit: addSaveHandler,
      content: (
        <Form
          ref={FormRef}
          refs={[name, role, deploymentSelectorRef, tokenGenRef, disableRef]}
        >
          <Row>
            <Field
              {...fieldProps}
              label="Name"
              ref={name}
              required={true}
              type={"text"}
              name={"name"}
              width={"200px"}
              disabled={processing}
              value={currentObj.name || ""}
            />
          </Row>
          <Row>
            <Field
              {...fieldProps}
              required={true}
              label="Role"
              type={FieldTypes.SINGLE_SELECT}
              ref={role}
              value={selectedRole}
              values={roleList}
              response={{ code: 200 }}
              name={"role"}
            />
          </Row>
          <Row>
            <AccessTokenDeploymentSelector
              {...fieldProps}
              label={"Deployment"}
              deploymentSelectorRef={deploymentSelectorRef}
              selectedDeployment={selectedDeployment}
              name={"deployment_id"}
              allowSelectAll={false}
            />
          </Row>

          {!isAdd && (
            <TokenGen
              {...fieldProps}
              label={"Token"}
              name={"token"}
              origData={{ ...atObj }}
              accesssToken={currentObj.token}
              tokenGenRef={tokenGenRef}
              regenerating={atObj.regenerating}
              showParentDialog={showDialog}
              updateCallback={regenCallback}
            />
          )}

          <Row>
            <Field
              {...fieldProps}
              ref={disableRef}
              label={" "}
              type={FieldTypes.BUTTON_GROUP}
              css={["md", "dark"]}
              name={"disable"}
              values={tokenEnableList}
              value={tokenEnabled}
            />
          </Row>
        </Form>
      ),
    };

    return Promise.resolve().then(() => openModalDialog(modalDialogSettings));
  };

  if (isAdd) {
    return (
      <Button onClick={() => showDialog({})} processing={processing}>
        <FontAwesomeIcon icon={faPlus} />
        <span>Add Access Token</span>
      </Button>
    );
  } else {
    return null;
  }
});

import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  Column,
  Field,
  FieldTypes,
  Form,
  Row,
} from "athena-next-ui-lib";
import { GroupDeploymentSelector } from "./GroupDeploymentSelector";

export const GroupForm = forwardRef((props, ref) => {
  const { data, processing } = props;

  const [selectedDeploymentIDs, setSelectedDeploymentIDs] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [deploymentSelectorDisabled, setDeploymentSelectorDisabled] =
    useState(null);

  const nameRef = useRef();
  const roleRef = useRef();
  const deploymentSelectorRef = useRef();

  const roleList = [
    { label: "Owner", value: "owner" },
    { label: "Admin", value: "admin" },
    { label: "Editor", value: "editor" },
    { label: "Viewer", value: "viewer" },
  ];

  const fieldProps = {
    labelWidth: "105px",
    fieldWidth: "200px",
  };

  useImperativeHandle(ref, () => ({
    getFormValues,
  }));

  useEffect(() => {
    if (!data.role) {
      //set default to viewer role
      setSelectedRole([{ label: "Viewer", value: "viewer" }]);
    } else {
      const matchedRole = roleList.find((item) => item.value === data.role);
      setSelectedRole([matchedRole]);
      setSelectedDeploymentIDs(data.deployment_ids || []);
    }
  }, [data.role]);

  useEffect(() => {
    if (
      selectedRole !== null &&
      selectedDeploymentIDs !== null &&
      deploymentSelectorDisabled !== null
    ) {
      drawForm();
    }
  }, [selectedRole, selectedDeploymentIDs, deploymentSelectorDisabled]);

  const getFormValues = () => {
    return FormRef.current.getValues();
  };

  const onUpdateValue = (selValues) => {
    if (selValues?.[0]?.value === "owner") {
      setSelectedDeploymentIDs([]);
      setDeploymentSelectorDisabled(true);
    } else {
      setDeploymentSelectorDisabled(false);
    }
  };

  const drawForm = () => {
    return (
      <Form ref={props.form} refs={[nameRef, roleRef, deploymentSelectorRef]}>
        <Row>
          <Column width={"400px"}>
            <Field
              {...fieldProps}
              label="Name"
              ref={nameRef}
              required={true}
              type={"text"}
              name={"name"}
              disabled={processing}
              value={data?.name || ""}
            />
          </Column>
        </Row>

        <Row>
          <Column width={"400px"}>
            <Field
              {...fieldProps}
              label="Role"
              type={FieldTypes.SINGLE_SELECT}
              ref={roleRef}
              value={selectedRole || []}
              values={roleList}
              response={{ code: 200 }}
              name={"role"}
              onUpdateValue={onUpdateValue}
            />
          </Column>
        </Row>

        <Row>
          <Column width={"400px"}>
            <GroupDeploymentSelector
              {...fieldProps}
              label={"Deployments"}
              deploymentSelectorRef={deploymentSelectorRef}
              selectedDeployments={selectedDeploymentIDs || []}
              name={"deployment_ids"}
              disabled={deploymentSelectorDisabled || false}
            />
          </Column>
        </Row>
      </Form>
    );
  };

  return drawForm();
});

import {appdynamics} from "./appdynamics";
import {datadog, datadogDashboardExtension} from "./datadog";
import {dynatrace } from "./dynatrace";
import {newrelic} from "./newrelic";
import {sciencelogic, sciencelogicDashboardExtension} from "./sciencelogic";
import {pixie} from "./pixie";

import {opsgenie} from "./opsgenie";
import {opsramp} from "./opsramp";
import {pagerduty} from "./pagerduty";
import {victorops} from "./victorops";
import {webhook, webhookInbound} from "./webhook";
import {teams} from "./teams";
import {webex} from "./webex";
import {slack} from "./slack";
import {email} from "./email";
import {grafanaDashboardExtension} from "./grafana";
import {elasticDashboardExtension} from "./elastic";
import {mattermost} from "./mattermost";



export const SIGNAL_DIRECTIONS = {
    IN: "incoming",
    OUT: "outgoing"
}

export const UsedFor2DirectionMap = {
    signal: SIGNAL_DIRECTIONS.IN,
    detection: SIGNAL_DIRECTIONS.OUT,
    notification: SIGNAL_DIRECTIONS.OUT,
    createIncident: SIGNAL_DIRECTIONS.OUT
}

export const IntegrationDisplayLookup = {
    "appdynamics": "AppDynamics",
    "datadog": "DataDog",
    "dynatrace": "Dynatrace",
    "elastic": "Elastic Stack",
    "email": "Email Distribution",
    "generic": "Incoming RCA",
    "grafana": "Grafana",
    "mattermost": "Mattermost",
    "newrelic": "New Relic",
    "opsgenie": "Opsgenie",
    "opsramp": "OpsRamp",
    "pagerduty": "PagerDuty",
    "pixie": "Pixie",
    "sciencelogic": "ScienceLogic",
    "slack": "Slack",
    "teams": "Microsoft Teams",
    "victorops": "VictorOps",
    "webexteams": "Webex Teams",
    "webhook": "Outgoing RCA",
}

export const IntegrationDefinitionMap = {
    "appdynamics": appdynamics,
    "datadog": datadog,
    "dynatrace": dynatrace,
    "email": email,
    "newrelic": newrelic,
    "opsgenie": opsgenie,
    "opsramp": opsramp,
    "pagerduty": pagerduty,
    "pixie": pixie,
    "sciencelogic": sciencelogic,
    "slack": slack,
    "teams": teams,
    "webexteams": webex,
    "victorops": victorops,
    "webhook": webhook,
    "generic": webhookInbound,
    "mattermost": mattermost,

    "datadogDashboardExtension": datadogDashboardExtension,
    "grafanaDashboardExtension": grafanaDashboardExtension,
    "elasticDashboardExtension": elasticDashboardExtension,
    "sciencelogicDashboardExtension": sciencelogicDashboardExtension,
}




export const getIntegrationDefinition=( integrationType, signalDirection)=>{
    let integrationDef = IntegrationDefinitionMap[integrationType];

    const objKeysArr = Object.keys(integrationDef);
    const matchingKey = objKeysArr.find(key=>UsedFor2DirectionMap[key] === signalDirection);
    return integrationDef[matchingKey || signalDirection];
}

//matching db_field_name as key
export function updateFieldsArray( fieldsArray, fieldProps ) {
    let fieldsOutArray = fieldsArray;
    Object.keys(fieldProps).forEach((key)=>{
        const matchingIndex= fieldsOutArray.findIndex(fld=>fld.db_field_name === key);
        if( matchingIndex>-1 ) {
            //update existing field
            fieldsOutArray[matchingIndex].value = fieldProps[key];
        } else {
           //something wrong,
            console.log("--- field not found: "+key);
        }

    });
    return fieldsOutArray;
}


export function rebuildSection (definitionIn, fieldProps, section) {

    const definition = JSON.parse(JSON.stringify(definitionIn));
    //fieldProps passing in as {db_field_name: value} pairs
    let sectionFields = Array.isArray(definition)? [...definition] :  [...definition[section]];  //handle array
    Object.keys(fieldProps).forEach((key)=>{
        const matchingIndex= sectionFields.findIndex(fld=>(fld.db_field_name === key || fld.field_name === key));
        if( matchingIndex>-1 ) {
            //update existing field
            sectionFields[matchingIndex].value = fieldProps[key];
        } else {
            //add new field
            sectionFields.push({
                field_name: key,
                db_field_name: key,
                field_type: "hidden",
                value: fieldProps[key]
            })
        }

    });
    return sectionFields;
}

export function rebuildHiddenSection (definition, fieldProps) {
    return rebuildSection( definition, "hidden", fieldProps);
}

export const findTargetIntegration = (
    targetCategoryArr,
    category,
    integrationType
  ) => {
    let targetIntegration = null;
    if (targetCategoryArr?.length > 0) {
      targetCategoryArr.forEach((targetCategory) => {
        if (!targetIntegration) {
          targetIntegration = targetCategory.integrations.find((item) => {
            if (item.category) {
              return (
                item.integration === integrationType && category === item.category
              );
            } else {
              return item.integration === integrationType;
            }
          });
        }
      });
    }
    return targetIntegration;
  };




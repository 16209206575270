import React, {
  useState,
  useContext,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  PageContext,
  DataServiceContext,
  TableCrud,
  Utilities,
} from "athena-next-ui-lib";

import { DialogGroup } from "./DialogGroup";

export const GroupTable = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);

  const [listData, setListData] = useState(null);
  const [currentGroup, setCurrentGroup] = useState(null);

  const canFullyAccessGroup = dataServiceContext.AccessManifest(
    "canFullyEditFeature",
    "group-mgmt"
  );
  const groupDialog = useRef();
  const groupTableRef = useRef();

  useImperativeHandle(ref, () => ({
    saveGroupCallback,
  }));

  const saveGroupCallback = ()=>{
    groupTableRef?.current?.loadTableData();
  }

  const loadData = (sortCol) => {
    const sortColFld = sortCol ? sortCol.replace("-", "") : "name";
    const sortColObj = columnDefs.find((col) => col.fldName === sortColFld);
    const sortOn = sortColObj.internalSort ? "name" : sortCol;
    return Promise.resolve()
      .then(() => setListData(null))
      .then(() => dataServiceContext.fetch("group/read", { sort: [sortOn] }))
      .then((response) => {
        if (response.response.code == 200) {
          //this.setState({groups: response||{}})
          const userProfile = dataServiceContext.getUserProfile();
          const deployments = userProfile.deployments || [];
          const groupsData = response.data.map((grp) => {
            const deploymentNames = grp.deployment_ids.map((depID) => {
              return deployments.find((d) => d.id == depID)?.name;
            });

            const deploymentNameDisplay =
              deploymentNames?.length < 1
                ? "All"
                : [...deploymentNames].join(", ");

            return {
              ...grp,
              label: grp.name,
              value: grp.id,
              deployment_names: deploymentNameDisplay,
            };
          });

          setListData([...groupsData]);
          return [...groupsData];
        }
      });
  };

  const isGroupDeletable = (user) => {
    return canFullyAccessGroup;
  };

  const getDeleteConfirmObj = (obj) => {
    return {
      title: "Delete Group",
      desc: (
        <span>
          Are you sure you want to delete this group: <b>{obj.name}</b>?
        </span>
      ),
    };
  };

  const editGroupAction = (group) => {
    return Promise.resolve()
      .then(() => setCurrentGroup({ ...group }))
      .then(() => groupDialog.current.showDialog(group));
  };

  const deleteGroupHandler = (group) => {
    return Promise.resolve()
      .then(() => dataServiceContext.fetch("group/delete", { id: group.id }))
      .then((response) => {
        try {
          if (response.response.code === 200)
            groupTableRef.current.loadTableData();
        } catch (err) {
          console.log("Err:" + err.message);
        }
        return response;
      });
  };

  const displayModifiedInfo = (row) => {
    return (
      <>
        <div>
          <b>{Utilities.TZ.timeAgo(row.ts, pageContext.timeZone)}</b>
        </div>
        <div style={{ opacity: 0.75 }}>by&nbsp;{row.modify_user_name}</div>
      </>
    );
  };

  const columnDefs = [
    { label: "Group Name", fldName: "name", width: "20%", sort: "asc" },
    { label: "Role", fldName: "role", width: "10%", internalSort: true },
    {
      label: "Deployment(s)",
      fldName: "deployment_names",
      width: "50%",
      internalSort: true,
    },
    {
      label: "Modified",
      renderer: displayModifiedInfo,
      fldName: "ts",
      width: "20%",
    },
  ];
  const rowActions = canFullyAccessGroup
    ? {
        edit: {
          isAllowed: isGroupDeletable,
          handler: editGroupAction,
        },
        delete: {
          isAllowed: isGroupDeletable,
          handler: deleteGroupHandler,
          confirm: getDeleteConfirmObj,
        },
      }
    : {};

  return (
    <>
      <TableCrud
        ref={groupTableRef}
        loadData={loadData}
        listData={listData}
        columnDefs={columnDefs}
        rowActions={rowActions}
        maxHeight={"calc(100vh - 100px)"}
      />
      <DialogGroup
        action={"EDIT"}
        ref={groupDialog}
        groupObj={currentGroup}
        updateCallback={saveGroupCallback}
      />
    </>
  );
});

import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import styles from "/pages/auth/sign-in.module.scss";
import { Column, Field, Row } from "athena-next-ui-lib";

export const PasswordReset = forwardRef((props, ref) => {
  const { stepId, className: clsName, onSubmit } = props;

  const [account, setAccount] = useState(null);
  const DOM_password = useRef();
  const DOM_confirmPassword = useRef();

  const setFocus = () => {
    document.getElementById("password-1").focus();
  };

  useImperativeHandle(ref, () => ({
    getNewPassword,
    setAccount,
    setFocus,
  }));

  const getNewPassword = () => {
    return DOM_password.current.getValue();
  };

  const validatePassword = () => {
    if (!DOM_password.current || !DOM_confirmPassword.current) return;

    const password1 = DOM_password.current.getValue();
    const password2 = DOM_confirmPassword.current.getValue();
    const isValid = (password1 || "").length > 0 && password1 == password2;

    if (props.passwordResetValidationCallback) {
      return props.passwordResetValidationCallback(isValid);
    }
  };

  const resetTitle = account?.invite_pending
    ? "Set Password"
    : "Reset Password";
  const emailAddress = account?.name || props?.emailAddress || "unknown";

  return (
    <div id={stepId} className={clsName}>
      <Column>
        <Row>
          <div className={styles.resetPasswordTitle}>
            <span>Hi, {emailAddress},</span>
          </div>
        </Row>
        <Row>
          <div className={styles.resetPasswordSubtitle}>
            <span>Set a password to gain access to your account:</span>
          </div>
        </Row>
        <Row align={"center"}>
          <Column>
            <Row className={styles.pwdFieldRow}>
              {props.visible && (
                <Field
                  type={"new-password"}
                  name={"new_pwd"}
                  required={true}
                  fieldId={"password-1"}
                  ref={DOM_password}
                  placeholder="Enter New Password"
                  onSubmit={onSubmit}
                  onUpdateValue={() => validatePassword()}
                  relatedFieldId={"password-2"}
                />
              )}
            </Row>
            <Row className={styles.pwdFieldRow}>
              {props.visible && (
                <Field
                  name={"new_pwd2"}
                  type={"new-password"}
                  ref={DOM_confirmPassword}
                  required={true}
                  fieldId={"password-2"}
                  placeholder="Re-enter New Password"
                  onSubmit={onSubmit}
                  onUpdateValue={() => validatePassword()}
                  relatedFieldId={"password-1"}
                />
              )}
            </Row>
          </Column>
          <Column>{props.children}</Column>
        </Row>
        <Row>
          <div className={styles.stepMain}>
            <p>
              By signing up or signing in, you agree to our{" "}
              <a
                className="link"
                href={`https://sciencelogic.com/company/legal`}
                target={"_blank"}
              >
                Terms of Service
              </a>
            </p>
          </div>
        </Row>
      </Column>
    </div>
  );
});

PasswordReset.getInitialProps = async (ctx) => {
  return {};
};

import {
  commonSection,
  detectionSectionTop,
  outEnabled,
} from "./CommonFields";
import { rebuildSection, updateFieldsArray } from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";

//--------------------------------------------------------------
// opsramp
//--------------------------------------------------------------
export const opsramp = {
  common: rebuildSection(commonSection, {
    category: IntegrationCategories.INCIDENT_MANAGEMENT,
    integration: "opsramp",
    out_event_type: "zebrium_incident",
    auth_scheme: "basic",
    out_alert_on_new_incidents: false,
    out_enabled: false,
  }),
  createIncident: {
    top: [outEnabled],
    APIConfig: [
      {
        field_name: "sectionTitle",
        field_type: "read-only",
        value: "API Configuration",
      },
      {
        field_name: "accessTokenURL",
        required: true,
        label: "Access Token URL",
        db_field_name: "out_argument1",
        field_type: "text",
      },
      {
        field_name: "incidentCreateURL",
        required: true,
        label: "Incident Create URL",
        db_field_name: "out_webhook",
        field_type: "text",
      },
      {
        field_name: "tenantId",
        required: true,
        label: "Tenant Id",
        db_field_name: "resource_id",
        field_type: "text",
      },
      {
        field_name: "apiKey",
        required: true,
        label: "API Key",
        db_field_name: "auth_username",
        field_type: "text",
      },
      {
        field_name: "apiSecret",
        required: true,
        label: "API Secret",
        db_field_name: "auth_password",
        field_type: "text",
      },
      ...updateFieldsArray(detectionSectionTop, {
        out_alert_on_new_incidents: false,
      }),
    ],
    order: ["top", "APIConfig"], //ordering of ui sections
  },
};

import {
  commonSection,
  detectionSectionTop,
  outEnabled,
} from "./CommonFields";
import { rebuildSection } from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";

//--------------------------------------------------------------
// slack
//--------------------------------------------------------------
export const slack = {
  common: rebuildSection(commonSection, {
    category: IntegrationCategories.NOTIFICATIONS,
    integration: "slack",
    out_event_type: "zebrium_incident,zebrium_slack",
    alert_on_new_incidents: true,
  }),
  notification: {
    top: [outEnabled],
    slackConfig: [
      {
        field_name: "webhookURL",
        required: true,
        label: "Slack Webhook URL",
        db_field_name: "out_webhook",
        field_type: "text",
      },
    ],
    bottom: [...detectionSectionTop],
    order: ["top", "slackConfig", "bottom"], //ordering of ui sections
  },
};

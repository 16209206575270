import {
  commonSection,
  detectionSectionTop,
  outEnabled,
} from "./CommonFields";
import {
  rebuildSection,
} from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";
//--------------------------------------------------------------
// victorops
//--------------------------------------------------------------
export const victorops = {
  common: rebuildSection(commonSection, {
    category: IntegrationCategories.INCIDENT_MANAGEMENT,
    integration: "victorops",
    out_event_type: "zebrium_incident",
    out_alert_on_new_incidents: false,
    out_enabled: false,
  }),
  createIncident: {
    top: [outEnabled],
    webhookConfig: [
      {
        field_name: "webhookURL",
        required: true,
        label: "Webhook URL",
        db_field_name: "out_webhook",
        field_type: "text",
      },
      ...detectionSectionTop,
    ],
    order: ["top", "webhookConfig"], //ordering of ui sections
  },
};

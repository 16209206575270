import { commonSection, inEnabled, outEnabled, signalSectionBottom} from "./CommonFields";
import {rebuildSection} from "./IntegrationDefinitionUtils";
import {INTEGRATION_TEST_TYPES, IntegrationCategories} from "./IntegrationConsts";


//--------------------------------------------------------------
// appdynamics
//--------------------------------------------------------------
export const appdynamics ={
    common: rebuildSection( commonSection, {
        integration: "appdynamics",
        category: IntegrationCategories.OBSERVABILITY_DASHBOARD,
        out_event_type: "zebrium_incident,zebrium_monitor_metrics",
        auth_scheme: "oauth"
    }),
    signal : {
        hideOnCreate: true,
        top:  [inEnabled, {
            field_name: "tabTitle",
            field_type: "description",
            value: "AppDynamics can be configured to send signals to Zebrium. Zebrium will search for root cause indicators in your logs around the time of the signal. If found, Zebrium will send the results as an event payload to AppDynamics."
        }],
        APIConfig: [
            {
                value:"Complete these fields to receive signals from AppDynamics...",
                field_name:"sectionTitle",
                field_type:"read-only"
            },
            {
                field_name:"controllerURLIn",
                required: true,
                label: "Controller URL",
                db_field_name: "base_url",
                field_type: "text",
                tooltip: "This can be found on the My AppDynamics Accounts Page."
            }, {
                field_name:  "accountNameIn",
                required: true,
                label: "Account Name",
                db_field_name: "out_argument1",
                field_type: "text",
                tooltip: "This can be found in the License Summary Page in AppDynamics."
            }, {
                field_name:  "applicationIDIn",
                required: true,
                label: "Application Id",
                db_field_name: "resource_id",
                field_type: "text",
                tooltip: "This can be found in the browser URL when viewing AppDynamics."
            }, {
                field_name: "apiClientNameIn",
                required: true,
                label: "API Client Name",
                db_field_name: "auth_username",
                field_type: "text",
                tooltip: "This is the Client Name you used when you created an API client in AppDynamics."
            },{
                field_name: "clientSecretIn",
                required: true,
                label: "Client Secret",
                db_field_name: "auth_password",
                field_type: "text",
                tooltip: "This is the secret that was generated when you created an API client in AppDynamics."
            }],
        bottom: signalSectionBottom,
        order: ["top", "APIConfig", "bottom"]  //ordering of ui sections
    },
    detection: {
        top:  [outEnabled, {
            field_name: "tabTitle",
            field_type: "description",
            value:"Detections occur whenever Zebrium finds a correlated set of root cause indicators in the logs (these form a root cause report summary). Detections are sent as event payloads to AppDynamics.",
        }],
        APIConfig: [
            {
                value:"Complete these fields to send detections to AppDynamics...",
                field_name:"sectionTitle",
                field_type:"read-only"
            },
            {
            field_name:"controllerURL",
            required: true,
            label: "Controller URL",
            db_field_name: "base_url",
            field_type: "text",
            tooltip: "This can be found on the My AppDynamics Accounts Page."
        }, {
            field_name:  "accountName",
            required: true,
            label: "Account Name",
            db_field_name: "out_argument1",
            field_type: "text",
            tooltip: "This can be found in the License Summary Page in AppDynamics."
        }, {
            field_name:  "applicationID",
            required: true,
            label: "Application Id",
            db_field_name: "resource_id",
            field_type: "text",
            tooltip: "This can be found in the browser URL when viewing AppDynamics."
        }, {
            field_name: "apiClientName",
            required: true,
            label: "API Client Name",
            db_field_name: "auth_username",
            field_type: "text",
            tooltip: "This is the Client Name you used when you created an API client in AppDynamics."
        },{
            field_name: "clientSecret",
            required: true,
            label: "Client Secret",
            db_field_name: "auth_password",
            field_type: "text",
            tooltip: "This is the secret that was generated when you created an API client in AppDynamics."
        },{
            field_name: "test-integration",
            required: true,
            label: "Create Sample Alert",
            test_type: INTEGRATION_TEST_TYPES.CONNECT_AND_CREATE,
            field_type: "button",
            tooltip: "Create an API key under Organization Settings in Datadog."
        }],
        order: ["top", "APIConfig"]  //ordering of ui sections
    }
}
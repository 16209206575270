import React, { useContext, useState, useEffect, useRef, useMemo } from "react";

import {
  Button,
  DataServiceContext,
  useModalDialog,
  Field,
  PageContext,
} from "athena-next-ui-lib";
import {
  useWizardDialog,
  WizardButtonBar,
  WizardTab,
  WizardTabset,
  WizardTitlebar,
  getIntegrationDefinition,
  IntegrationDefinitionMap,
  SIGNAL_DIRECTIONS,
  IntegrationDataDriven,
  IntegrationCategories,
} from "/components-biz";
import styles from "./WizardDialog.module.scss";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const IntegrationFormContext = React.createContext();

const tabContentStyles = {
  maxHeight: "300px",
  minHeight: "300px",
  minWidth: "100%",
  width: "550px",
  maxWidth: "600px",
};

export function IntegrationEditorWizard(props) {
  const dataServiceContext = useContext(DataServiceContext);
  const pageContext = useContext(PageContext);
  const { openModalDialog } = useModalDialog();
  const [content, setContent] = useState();
  const commonTabRef = useRef();
  const detectionTabRef = useRef();
  const signalTabRef = useRef();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [count, setCount] = useState(1);

  const [processing, setProcessing] = useState(false);

  const wizardTabsetRef = useRef();

  //---- integrationFormContext data

  /*todo: @Ko: when we store current integration data on state it will always be one version behind current when the component renders;
            so we must apply useRef and store this data under current so it is immediately available
            below I replicate the interface of the sharedIntegrationData state variable while actually
            using useRef to store the data -- in this way none of the references to sharedIntegrationData
             need to know about accessing values through .current*/

  const sharedIntegrationDataStore = useRef({ ...props.integrationData });

  const isEditMode = sharedIntegrationDataStore?.current?.siid?.length > 0;

  const sharedIntegrationData = sharedIntegrationDataStore.current;
  const setSharedIntegrationData = (updatedData) => {
    sharedIntegrationDataStore.current = {
      ...sharedIntegrationDataStore.current,
      ...updatedData,
    };
    deriveTabs();
    setCount(count + 1);
  };
  const [serviceGroupsData, setServiceGroupsData] = useState(null);

  const [commonTabHasErrors, setCommonTabHasErrors] = useState(false);
  const [detectionTabHasErrors, setDetectionTabHasErrors] = useState(false);
  const [signalTabHasErrors, setSignalTabHasErrors] = useState(false);

  //---- integrationFormContext ------------

  useEffect(() => {
    deriveTabs();
  }, [commonTabHasErrors, detectionTabHasErrors, signalTabHasErrors]);

  const deriveTabs = () => {
    let tabList = [];
    const commonTab = getIntegrationCommonTab(
      props.integration,
      {},
      commonTabRef
    );
    tabList.push(commonTab);

    let integrationData = {};
    if (props?.integrationData.siid) {
      integrationData = { ...props.integrationData };
    }

    const detectionTab = getIntegrationDetectionTab(
      props.integration,
      integrationData,
      detectionTabRef
    );
    if (detectionTab) {
      tabList.push(detectionTab);
    }
    const signalTab = getIntegrationSignalTab(
      props.integration,
      integrationData,
      signalTabRef
    );
    if (signalTab) {
      tabList.push(signalTab);
    }

    setContent(tabList);
  };

  const getIntegrationCommonTab = (
    selectedIntegrationType,
    integrationData,
    ref
  ) => {
    const isAccessToken = props.category === "dashboard-extension";
    const selIntegrationType = isAccessToken
      ? selectedIntegrationType + "DashboardExtension"
      : selectedIntegrationType;
    const definitionTopLevel = IntegrationDefinitionMap[selIntegrationType];
    const definition = isAccessToken
      ? definitionTopLevel
      : definitionTopLevel.common;

    const commonProps = {
      data: { ...integrationData },
      action:
        sharedIntegrationDataStore.current.siid?.length > 0 ? "EDIT" : "ADD",
      integrationDefinition: definition,
      integrationType: selectedIntegrationType,
      isIntegrationFormValid: isIntegrationFormValid,
    };
    const content = <IntegrationDataDriven {...commonProps} ref={ref} />;
    const title = (
      <>
        <div className={styles.majorLabel}>General</div>
        <div className={styles.minorLabel}>&nbsp;</div>
      </>
    );

    return (
      <WizardTab
        css={`
          ${styles.form}
        `}
        title={title}
        type={"general"}
        errors={commonTabHasErrors}
        styles={tabContentStyles}
      >
        {content}
      </WizardTab>
    );
  };

  const getIntegrationDetectionTab = (
    selectedIntegrationType,
    integrationData,
    ref
  ) => {
    if (props.category === "dashboard-extension") return null;
    const definition = getIntegrationDefinition(
      selectedIntegrationType,
      SIGNAL_DIRECTIONS.OUT
    );
    if (!definition) return null;

    //-----------------------------------
    // Outbound form
    //-----------------------------------
    const outboundProps = {
      data: { ...integrationData },
      integrationDefinition: definition,
      integrationType: selectedIntegrationType,
      isIntegrationFormValid: isIntegrationFormValid,
    };

    const detectionTabDisplay =
      selectedIntegrationType === "pixie"
        ? "Collect Telemetry"
        : "Send Detections";

    const title = (
      <>
        <div className={styles.majorLabel}>{detectionTabDisplay}</div>
        <div className={styles.minorLabel}>
          {sharedIntegrationDataStore.current.out_enabled === true
            ? "Enabled"
            : "Disabled"}
        </div>
      </>
    );
    const content = <IntegrationDataDriven {...outboundProps} ref={ref} />;
    return (
      <WizardTab
        css={`
          ${styles.form}
        `}
        title={title}
        type={"detection"}
        errors={detectionTabHasErrors}
        styles={tabContentStyles}
      >
        {content}
      </WizardTab>
    );
  };

  const getIntegrationSignalTab = (
    selectedIntegrationType,
    integrationData,
    ref
  ) => {
    if (props.category === "dashboard-extension") return null;
    const definition = getIntegrationDefinition(
      selectedIntegrationType,
      SIGNAL_DIRECTIONS.IN
    );
    if (!definition || (!isEditMode && definition.hideOnCreate)) return null;
    //-----------------------------------
    // Inbound form
    //-----------------------------------
    const inboundProps = {
      data: { ...integrationData },
      integrationDefinition: definition,
      integrationType: selectedIntegrationType,
    };

    const title = (
      <>
        <div className={styles.majorLabel}>Receive Signals</div>
        <div className={styles.minorLabel}>
          {sharedIntegrationDataStore.current.in_enabled === true
            ? "Enabled"
            : "Disabled"}
        </div>
      </>
    );
    const content = <IntegrationDataDriven {...inboundProps} ref={ref} />;
    return (
      <WizardTab
        css={`
          ${styles.form}
        `}
        title={title}
        type={"signal"}
        errors={signalTabHasErrors}
        styles={tabContentStyles}
      >
        {content}
      </WizardTab>
    );
  };

  const openHelp = () => {
    const selectedTabType = wizardTabsetRef.current.getSelectedTabType(); //general, detection, signal

    let helpLink = props.help;
    if (selectedTabType.includes("detection")) {
      helpLink = props.helpDetection || helpLink;
    } else if (selectedTabType.includes("signal")) {
      helpLink = props.helpSignal || helpLink;
    }

    window.open(
      helpLink,
      "zebrium-help",
      "toolbars=0,width=640,height=480,left=200,top=200,scrollbars=1,resizable=1"
    );
  };

  const isIntegrationFormValid = () => {
    const payload = sharedIntegrationDataStore.current;

    const commonValid = commonTabRef?.current
      ? commonTabRef.current.isValid()
      : true;
    const detectionValid =
      detectionTabRef?.current && payload.out_enabled
        ? detectionTabRef.current.isValid()
        : true;
    const signalValid =
      signalTabRef?.current && payload.in_enabled
        ? signalTabRef.current.isValid()
        : true;

    setCommonTabHasErrors(!commonValid);
    setDetectionTabHasErrors(!detectionValid);
    setSignalTabHasErrors(!signalValid);

    return commonValid && detectionValid && signalValid;
  };

  const saveIntegration = () => {
    const payload = sharedIntegrationDataStore.current;
    const isAdd = !payload.siid;

    /*todo:
            @ko, I'm thinking that IntegrationDataDriven exposes an isValid method
            1. when a required value in the form is not satisfied two things happen...
                a. IntegrationDataDriven adds an errors div at the top of the form; this means the errors
                    for that form will be displayed at the top of that form, which places them at the top of
                    the content area for the related tab
                b. IntegrationDataDriven.isValid() returns false
            2. when all required values in the related form are satisfied IntegrationDataDriven.isValid() returns true
            3. tabs that are not relevant for the integration (hidden) still return true when IntegrationDataDriven.isValid()
               is called on them; in this way we have a pattern we can depend on to write our conditions.
            4. when all three tabs return true on IntegrationDataDriven.isValid() we know that the data held in
               sharedIntegrationData is valid and so we can safely call the integrations/create API to save the changes
            5. I assume that the sharedIntegrationData will contain an siid for integrations that are being edited and
               will not contain one for newly defined integrations -- in this way integrations/create will succeed in both
               cases
            6. when save integration succeeds we call initPage again to fetch the new set of integrations (and re-evaluate the counts of integrations)
            */

    //return; // early return since the below is not yet implemented

    if (!isIntegrationFormValid()) {
      return;
    }

    //go through encrypted fields, remove from payload if already set
    [
      "auth_password",
      "auth_token",
      "in_auth_password",
      "in_auth_token",
    ].forEach((encFld) => {
      if (payload[encFld]?.indexOf("...") === 0) {
        delete payload[encFld];
      }
    });

    const apiUrl = isAdd ? "integration/create" : "integration/update";
    const createAccessToken =
      payload.category === IntegrationCategories.DASHBOARD_EXTENSION;

    if (payload.out_email_list) {
      //need to convert email list to comma separated list without space
      let formatEmailList = payload.out_email_list;
      payload.out_email_list = formatEmailList
        .replace(/[,;]?\n|[,;] *| +/g, ",")
        .replace(/,$/g, "");
    }

    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() => dataServiceContext.fetch(apiUrl, payload))

      .then((saveIntegrationCall) => {
        if (
          createAccessToken &&
          saveIntegrationCall.response.code === 200 &&
          isAdd
        ) {
          //now create access token
          const integrationRow = saveIntegrationCall?.data?.[0];
          const atPayload = {
            name: integrationRow.name,
            integration_siid: integrationRow.siid,
            role: "viewer",
            deployment_id: integrationRow.db_schema,
          };
          return dataServiceContext.fetch("accesstoken/create", atPayload);
        } else {
          return saveIntegrationCall;
        }
      })
      .then((previousCall) => {
        if (previousCall.response.code === 200) {
          isAdd &&
            props.incrementIntegrationCount(
              payload.category,
              payload.integration
            );

          if (createAccessToken && isAdd) {
            const fields = (
              <>
                <Field
                  type={"copy"}
                  label="Endpoint URL"
                  labelAlign={"top"}
                  height={30}
                  value={pageContext.env.ZAPI_URL}
                  fieldWidth={"300px"}
                />
                <Field
                  type={"copy"}
                  label="Deployment ID"
                  labelAlign={"top"}
                  height={30}
                  value={previousCall.data[0].deployment_id}
                  fieldWidth={"300px"}
                />
                <Field
                  type={"copy"}
                  label="Access Token"
                  labelAlign={"top"}
                  value={previousCall.data[0].token}
                  fieldWidth={"300px"}
                />
              </>
            );
            return openModalDialog({
              type: "warn",
              title: "Your Integration Info...",
              content: fields,
              submit: () => props.close(),
            });
          } else {
            //previousCall is saveIntegration call
            const responseData = previousCall?.data?.[0] || null;

            if (responseData?.in_enabled && isAdd && responseData.in_webhook) {
              const field = (
                <Field
                  type={"copy"}
                  value={previousCall.data[0].in_webhook}
                  fieldWidth={"300px"}
                />
              );
              return openModalDialog({
                type: "warn",
                title: "Your URL...",
                content: field,
                submit: () => props.close(),
              });
            } else {
              props.close();
            }
          }
        } else {
          return openModalDialog({
            type: "warn",
            title: "Error Saving Integration",
            content: previousCall.response.message,
          });
        }
      })
      .finally(() => setProcessing(false));
  };

  const nextTab = () => {
    wizardTabsetRef.current.setSelectedTabByIndex(selectedTabIndex + 1);
  };

  const selectTabCallback = (index) => {
    setSelectedTabIndex(index);
  };

  let buttons;

  if (isEditMode) {
    buttons = (
      <Button
        type={"primary"}
        processing={processing}
        disabled={processing}
        onClick={() => saveIntegration()}
      >
        Save
      </Button>
    );
  } else {
    //draw next buttons
    switch (true) {
      case selectedTabIndex === 0 && content?.length > 1:
        buttons = (
          <>
            <Button
              type={"primary"}
              processing={processing}
              onClick={() => nextTab()}
            >
              Next&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </>
        );
        break;
      case selectedTabIndex > 0 && selectedTabIndex < content?.length - 1:
        buttons = (
          <div>
            <Button
              type={"primary"}
              processing={processing}
              disabled={processing}
              onClick={() => saveIntegration()}
            >
              Save
            </Button>

            <Button
              type={"secondary"}
              processing={processing}
              onClick={() => nextTab()}
            >
              Next&nbsp;&nbsp;
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          </div>
        );
        break;
      default:
        buttons = (
          <>
            <Button
              type={"primary"}
              processing={processing}
              disabled={processing}
              onClick={() => saveIntegration()}
            >
              Save
            </Button>
          </>
        );
        break;
    }
  }

  const formContextValueParams = useMemo(
    () => ({
      sharedIntegrationData,
      setSharedIntegrationData,
      serviceGroupsData,
      setServiceGroupsData,
      sharedIntegrationDataStore,
    }),
    []
  );

  return (
    <IntegrationFormContext.Provider value={formContextValueParams}>
      <WizardTitlebar
        {...props}
        title={`${isEditMode ? "Edit" : "Create"} ${props.wizardTitle}`}
      >
        <Button type={"secondary"} onClick={openHelp}>
          Help
        </Button>
      </WizardTitlebar>

      <WizardTabset ref={wizardTabsetRef} selectTabCallback={selectTabCallback}>
        {content}
      </WizardTabset>

      <WizardButtonBar>
        <Button
          type={"secondary"}
          onClick={() => {
            props.close();
          }}
        >
          Cancel
        </Button>
        <div>{buttons}</div>
      </WizardButtonBar>
    </IntegrationFormContext.Provider>
  );
}

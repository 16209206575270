import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  Column,
  DataServiceContext,
  Field,
  FieldTypes,
  Form,
  Row,
} from "athena-next-ui-lib";

export const UserForm = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);

  const FormRef = useRef();
  const nameRef = useRef();
  const first_nameRef = useRef();
  const last_nameRef = useRef();
  const disableRef = useRef();
  const groupsRef = useRef();
  const idRef = useRef();

  const [groups, setGroups] = useState(null);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [response, setResponse] = useState({ code: 0 });

  const { data, processing, action } = props;
  const isAdd = action === "ADD";

  const fieldProps = {
    labelWidth: "105px",
    fieldWidth: "200px",
  };

  useImperativeHandle(ref, () => ({
    getFormValues,
    showUserForm,
  }));

  useEffect(() => {
    if (data.group_ids && groups) {
      const selGroups = groups.filter(
        (grp) => data.group_ids.indexOf(grp.id) > -1
      );
      setSelectedGroups(selGroups);
    }
  }, [data, groups]);

  const showUserForm = () => {
    return loadGroups();
  };

  const getFormValues = () => {
    return FormRef.current.getValues();
  };

  const loadGroups = () => {
    return Promise.resolve()
      .then(() => dataServiceContext.fetch("group/read", { sort: ["name"] }))
      .then((response) => {
        if (response.response.code == 200) {
          //this.setState({groups: response||{}})
          const groupsData = response.data.map((grp) => {
            return { ...grp, label: grp.name, value: grp.id };
          });

          setGroups([...groupsData]);
          setResponse({ ...response?.response });
        }
      });
  };

  return (
    <Form
      ref={FormRef}
      refs={[
        idRef,
        nameRef,
        first_nameRef,
        last_nameRef,
        groupsRef,
        disableRef,
      ]}
    >
      <Row>
        <Column width={"400px"}>
          <Field
            {...fieldProps}
            label="Email"
            ref={nameRef}
            required={isAdd}
            type={"text"}
            name={"name"}
            disabled={!isAdd || processing}
            value={data.name || ""}
          />
        </Column>
      </Row>

      <Row>
        <Column width={"400px"}>
          <Field
            {...fieldProps}
            label="First name"
            ref={first_nameRef}
            required={true}
            type={"text"}
            name={"first_name"}
            disabled={processing}
            value={data.first_name || ""}
          />
        </Column>
      </Row>

      <Row>
        <Column width={"400px"}>
          <Field
            {...fieldProps}
            label="Last name"
            ref={last_nameRef}
            type={"text"}
            required={true}
            name={"last_name"}
            disabled={processing}
            value={data.last_name || ""}
          />
        </Column>
      </Row>

      <Row>
        <Column width={"400px"}>
          <Field
            {...fieldProps}
            label={"Group(s)"}
            type={FieldTypes.MULTI_SELECT}
            ref={groupsRef}
            required={true}
            response={response}
            placeholder={"Select group(s)"}
            values={groups || []}
            value={selectedGroups}
            name={"group_ids"}
          />
        </Column>
      </Row>

      {!isAdd && (
        <Row>
          <Column width={"400px"}>
            <Field
              {...fieldProps}
              label="Disable Access"
              ref={disableRef}
              type={"checkbox"}
              value={data.disable}
              name={"disable"}
            />
            <Field ref={idRef} type={"hidden"} value={data.id} name={"id"} />
          </Column>
        </Row>
      )}
    </Form>
  );
});

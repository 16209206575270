import {signalSectionBottom, commonSection, inEnabled, outEnabled, commonDashboardExtension} from "./CommonFields";
import {rebuildSection} from "./IntegrationDefinitionUtils";
import {INTEGRATION_TEST_TYPES, IntegrationCategories} from "./IntegrationConsts";

//--------------------------------------------------------------
// datadog
//--------------------------------------------------------------
export const datadog ={
    common: rebuildSection( commonSection,  {
        integration: "datadog",
        category: IntegrationCategories.OBSERVABILITY_DASHBOARD,
        out_event_type:  "zebrium_incident,zebrium_monitor_metrics",
        auth_scheme: "basic"
    }),
    signal : {
        hideOnCreate: true,
        top:  [inEnabled, {
            field_name: "tabTitle",
            field_type: "description",
            value:"Datadog Monitors can be configured to send signals to Zebrium. Zebrium will search for root cause indicators in your logs around the time of the signal. If found, Zebrium will send the results as an event payload to Datadog.",

        }],
        APIConfig: [ {
            field_name: "apiKey",
            required: true,
            label: "API Key",
            db_field_name: "auth_password",
            field_type: "text",
            tooltip: "Create an API key under Organization Settings in Datadog."
        }],
        bottom: signalSectionBottom,
        order: ["top", "APIConfig", "bottom"]  //ordering of ui sections
    },
    detection:  [
        outEnabled,
        {
            field_name: "tabTitle",
            field_type: "description",
            value:"Detections occur whenever Zebrium finds a correlated set of root cause indicators in the logs (these form a root cause report summary). Detections are sent as event payloads to Datadog.",
        },
    {
        field_name: "apiKey2",
        required: true,
        label: "API Key",
        db_field_name: "auth_password",
        field_type: "text",
        tooltip: "Create an API key under Organization Settings in Datadog."
    }, {
        field_name: "test-integration",
        required: true,
        label: "Create Sample Alert",
        test_type: INTEGRATION_TEST_TYPES.CONNECT_AND_CREATE,
        field_type: "button",
        tooltip: "Create an API key under Organization Settings in Datadog."
    }],

}

export const datadogDashboardExtension = rebuildSection( commonDashboardExtension, {
    category: IntegrationCategories.DASHBOARD_EXTENSION,
    integration: "datadog",
    auth_scheme: "none",
    tabTitle: "This will generate an Access Token that will be needed when configuring this dashboard extension in Datadog."
})

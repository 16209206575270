export const IntegrationCategories = {
  LOG_COLLECTOR: "log-collector",
  OBSERVABILITY_DASHBOARD: "observability-dashboard",
  NOTIFICATIONS: "notification",
  DASHBOARD_EXTENSION: "dashboard-extension",
  INCIDENT_MANAGEMENT: "incident-management",
  WEBHOOKS: "webhook",
  TELEMETRY: "telemetry",
};

export const INTEGRATION_TEST_TYPES = {
  CONNECT_AND_CREATE: 0,
  CREATE_ONLY: 1,
};

import {signalSectionBottom, commonSection, outEnabled, inEnabled} from "./CommonFields";
import {rebuildSection} from "./IntegrationDefinitionUtils";
import {INTEGRATION_TEST_TYPES, IntegrationCategories} from "./IntegrationConsts";
//--------------------------------------------------------------
// newrelic
//--------------------------------------------------------------
export const newrelic ={
    common: rebuildSection( commonSection, {
        integration: "newrelic",
        category: IntegrationCategories.OBSERVABILITY_DASHBOARD,
        out_event_type:  "zebrium_incident,zebrium_monitor_metrics",
        auth_scheme: "basic",
        in_region: "US"
    }),
    signal : {
        hideOnCreate: true,
        top:  [inEnabled, {
            field_name: "tabTitle",
            field_type: "description",
            value: "New Relic Alert Policies can be configured to send signals to Zebrium. Zebrium will search for root cause indicators in your logs around the time of the signal. If found, Zebrium will send the results as an event payload to New Relic."
        }],
        APIConfig: [{
            field_name: "region",
            label: "Region",
            db_field_name: "in_region",
            field_type: "button-group",
            listValues: [{label:"US", value:"US"}, {label:"EU", value:"EU"}]
        },{
            field_name: "apiKey",
            required: true,
            label: "API Key",
            db_field_name: "auth_password",
            field_type: "text",
            tooltip: "Create an API key under API Keys in the Settings menu in New Relic."
        },{
            field_name: "accountId",
            required: true,
            label: "Account Id",
            db_field_name: "auth_username",
            field_type: "text",
            tooltip: "The account ID can be seen when you create an API key."
        }],
        bottom: signalSectionBottom,
        order: ["top", "APIConfig", "bottom"]  //ordering of ui sections
    },
    detection: [outEnabled,
        {
            field_name: "tabTitle",
            field_type: "description",
            value:"Detections occur whenever Zebrium finds a correlated set of root cause indicators in the logs (these form a root cause report summary). Detections are sent as event payloads to New Relic.",
        }, {
            field_name: "region2",
            label: "Region",
            db_field_name: "in_region",
            field_type: "button-group",
            listValues: [{label:"US", value:"US"}, {label:"EU", value:"EU"}]
        },
        {
        field_name: "accountId2",
        required: true,
        label: "Account Id",
        db_field_name: "auth_username",
        field_type: "text",
        tooltip: "The account ID can be seen when you create an API key."
    }, {
        field_name: "apiKey2",
        required: true,
        label: "API Key",
        db_field_name: "auth_password",
        field_type: "text",
        tooltip: "Create an API key under API Keys in the Settings menu in New Relic."
    },{
        field_name: "test-integration",
        required: true,
        label: "Create Sample Alert",
        test_type: INTEGRATION_TEST_TYPES.CONNECT_AND_CREATE,
        field_type: "button",
        tooltip: "Create an API key under Organization Settings in Datadog."
    }]
}
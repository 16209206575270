import React, {useEffect, useRef, useState} from "react";
import styles from "./in-out-bound.module.scss"

import {Column, Form, Row, getErrorMessageDisplay} from "athena-next-ui-lib";


export const IntegrationForm=(props ) =>{

    const {formDefs, formRef, fieldRefs, formOrdering } = props;

    const [error, setError] = useState(null);

    const errorRef = useRef();



    useEffect(()=>{
        if( props.formErrors === null) return;

        const frmValues = formRef?.current?.getValues();  //this is a workaround, error is not getting reflected\
        const frmErrors = frmValues.errors||[];
        const derivedErrors = props?.formErrors.length>0 ? props.formErrors : frmErrors;
        setError(derivedErrors?.length>0 ? getErrorMessageDisplay(derivedErrors) : null);
        setTimeout( ()=> {errorRef.current.scrollIntoView("alignToTop");}, 250)
    },[props.formErrors])


    const errorCSS = error ? styles.formError : styles.formErrorHidden;

    return   <Form ref={formRef} refs={fieldRefs}>
        <Row width={'*'}>
            <Column width={"100%"}>
                <div ref={errorRef} className={errorCSS}><strong>ERROR:</strong>&nbsp;{error}</div>
                {formOrdering.map( fldKey=>{

                    let formField = null;
                    if( formDefs ) {
                        //allow overriding formField from form caller
                        formField = formDefs?.[fldKey] || null;
                    }
                    return formField;

                })}
            </Column>
        </Row>
    </Form>
}


import React, {
  useState,
  useContext,
  useRef,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from "react";

import {
  PageContext,
  DataServiceContext,
  TableCrud,
  Utilities,
} from "athena-next-ui-lib";

import { DialogUser } from "./DialogUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faClock,
  faKey,
  faPaperPlane,
  faUser,
  faUserSlash,
} from "@fortawesome/free-solid-svg-icons";
import { DialogInviteUser } from "./DialogInviteUser";
import { UserMgmtUtils } from "/_utilities";

const USER_TABLE_ACTION_TYPE = {
  ADD: "ADD",
  EDIT: "EDIT",
  DELETE: "DELETE",
  ENABLE: "ENABLE",
  REINVITE: "REINVITE",
  RESETPWD: "RESET_PWD",
};
const USER_TABLE_STATUS = {
  ACTIVE: "ACTIVE",
  DISABLED: "DISABLED",
  INVITEPENDING: "INVITEPENDING",
  RESETPENDING: "RESETPENDING",
};

const SORT_COLUMN_MAP = {
  derived_name_email: ["first_name", "last_name", "name"],
  "-derived_name_email": ["-first_name", "-last_name", "-name"],
};

export const UserTable = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);

  const [inviteUserPassword, setInviteUserPassword] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState(null);
  const [groups, setGroups] = useState(null);
  const [listData, setListData] = useState(null);

  const userDialog = useRef();
  const inviteUserDialog = useRef();
  const loginUser = dataServiceContext.getUserProfile();
  const userTableRef = useRef();

  useImperativeHandle(ref, () => ({
    loadData,
    saveUserCallback,
  }));

  useEffect(() => {
    if (groups?.length > 0 && users?.length > 0) {
      const usersData = users.map((user) => {
        const derivedUserObj = getDerivedUserObject(user);

        return { ...derivedUserObj };
      });
      setListData([...usersData]);
    }
  }, [groups, users]);

  const getDerivedUserObject = (user) => {
    const groupIds = user.group_ids; //string array
    const groupNames = groupIds
      .map((gid) => {
        const foundGroup = (groups || []).find((grp) => grp.id == gid);
        return foundGroup?.name || "";
      })
      .filter((gn) => gn !== "")
      .join(", ");

    const userStatus = getUserStatus(user);

    return {
      ...user,
      derived_group_names: groupNames,
      derived_user_status: userStatus,
    };
  };

  const getUserStatusDisplay = (user) => {
    const type = getUserStatus(user);
    return (
      <>
        {type === USER_TABLE_STATUS.INVITEPENDING && (
          <span>
            <FontAwesomeIcon icon={faClock} size={"lg"} />
            &nbsp;Invite&nbsp;Pending
          </span>
        )}
        {type === USER_TABLE_STATUS.RESETPENDING && (
          <span>
            <FontAwesomeIcon icon={faClock} size={"lg"} />
            &nbsp;Reset&nbsp;Pending
          </span>
        )}
        {type === USER_TABLE_STATUS.ACTIVE && (
          <span>
            <FontAwesomeIcon icon={faCheck} size={"lg"} />
            &nbsp;Active
          </span>
        )}
        {type === USER_TABLE_STATUS.DISABLED && (
          <span>
            <FontAwesomeIcon icon={faUserSlash} size={"lg"} />
            &nbsp;Disabled
          </span>
        )}
      </>
    );
  };

  const deriveNameEmailColumn = (user) => {
    const currentProfile = dataServiceContext.getUserProfile();
    const isYou =
      currentProfile && user.name === currentProfile.name ? (
        <b style={{ color: "#ffcc00" }}>(you)</b>
      ) : null;
    return (
      <>
        <div>
          <b>
            {user.last_name}, {user.first_name}
          </b>
          &nbsp;{isYou}
        </div>
        <div>{user.name}</div>
      </>
    );
  };

  const displayModifiedInfo = (row) => {
    return (
      <>
        <div>
          <b>{Utilities.TZ.timeAgo(row.ts, pageContext.timeZone)}</b>
        </div>
        <div style={{ opacity: 0.75 }}>by&nbsp;{row.modify_user_name}</div>
      </>
    );
  };

  const columnDefs = [
    {
      label: "Status",
      renderer: getUserStatusDisplay,
      fldName: "derived_user_status",
      width: "15%",
      internalSort: true,
    },
    {
      label: "Name / Email",
      renderer: deriveNameEmailColumn,
      width: "20%",
      fldName: "derived_name_email",
      sort: "asc",
    },
    {
      label: "Group(s)",
      fldName: "derived_group_names",
      width: "40%",
      internalSort: true,
    },
    {
      label: "Modified",
      fldName: "ts",
      renderer: displayModifiedInfo,
      width: "25%",
    },
  ];

  const getUserStatus = (user) => {
    let userStatus = USER_TABLE_STATUS.ACTIVE; //active by default
    if (user.invite_pending) {
      userStatus = USER_TABLE_STATUS.INVITEPENDING;
    } else if (user.force_password_change && !user.disable) {
      userStatus = USER_TABLE_STATUS.RESETPENDING;
    } else if (!user.force_password_change && !user.disable) {
      userStatus = USER_TABLE_STATUS.ACTIVE;
    } else if (user.disable) {
      userStatus = USER_TABLE_STATUS.DISABLED;
    }
    return userStatus;
  };

  const getUserActionType = (userInput) => {
    let user = { ...userInput };
    if (!userInput.name) {
      user = listData.find((user) => user.id == userInput.id);
    }

    let userActionType = null;
    const isUserEnabled = !user.disable;
    const isUserInvitePending = !!user.invite_pending;
    const isForcePasswordChange = !!user.force_password_change;

    if (isUserEnabled && isUserInvitePending) {
      //user is enabled
      userActionType = USER_TABLE_ACTION_TYPE.REINVITE;
    } else if (isUserEnabled && isForcePasswordChange) {
      userActionType = USER_TABLE_ACTION_TYPE.RESETPWD;
    } else if (!isForcePasswordChange && isUserEnabled) {
      //already login in and enabled
      userActionType = USER_TABLE_ACTION_TYPE.RESETPWD;
    } else if (!isUserEnabled) {
      //user has been disabled
      userActionType = USER_TABLE_ACTION_TYPE.ENABLE;
    }
    return userActionType;
  };

  const isUserDeletable = (user) => {
    const isSsoEnabled = dataServiceContext.AccessManifest("isSsoEnabled");
    const canFullyAccessUser = !isSsoEnabled;
    if (!canFullyAccessUser) return false;

    const isCurrentlyLogin = user.name === loginUser.name; //current login user
    return !isCurrentlyLogin;
  };

  const getDeleteConfirmObj = (user) => {
    // this.setState({currentUser: user});
    return {
      title: "Delete User",
      desc: (
        <span>
          Are you sure you want to delete this user: <b>{user.name}</b>?
        </span>
      ),
    };
  };

  const getCustomConfirmObj = (user) => {
    setCurrentUser({ ...user });
    const actionType = getUserActionType(user);

    switch (actionType) {
      case USER_TABLE_ACTION_TYPE.ENABLE:
        return {
          icon: faUser,
          title: "Enable User",
          desc: (
            <span>
              Are you sure you want to enable this user: <b>{user.name}</b>?
            </span>
          ),
        };

      case USER_TABLE_ACTION_TYPE.RESETPWD:
        return {
          icon: faUser,
          title: "Reset User Password",
          desc: (
            <span>
              <u>Note</u>: Resetting this user's password invalidates any
              previous password reset links sent to this user.
            </span>
          ),
        };
      case USER_TABLE_ACTION_TYPE.REINVITE:
        return {
          icon: faUser,
          title: "Reinvite User",
          desc: (
            <span>
              <u>Note</u>: Reinviting this user invalidates any invite links
              previously sent to this user.
            </span>
          ),
        };
    }
  };

  const getUserCustomActionIcon = (user) => {
    const type = getUserActionType(user);
    const isCurrentlyLogin = user.name === loginUser.name; //current login user

    let actionIcon = null;

    if (type === USER_TABLE_ACTION_TYPE.REINVITE && !isCurrentlyLogin) {
      actionIcon = faPaperPlane;
    } else if (type === USER_TABLE_ACTION_TYPE.RESETPWD) {
      actionIcon = faKey;
    } else if (type === USER_TABLE_ACTION_TYPE.ENABLE && !isCurrentlyLogin) {
      actionIcon = faUser;
    }
    return actionIcon;
  };

  const getUserCustomActionLabel = (user) => {
    const type = getUserActionType(user);
    const isCurrentlyLogin = user.name === loginUser.name; //current login user

    let actionLabel = null;
    if (type === USER_TABLE_ACTION_TYPE.REINVITE && !isCurrentlyLogin) {
      actionLabel = "Reinvite";
    } else if (type === USER_TABLE_ACTION_TYPE.RESETPWD) {
      actionLabel = "Reset Pwd";
    } else if (type === USER_TABLE_ACTION_TYPE.ENABLE && !isCurrentlyLogin) {
      actionLabel = "Enable";
    }
    return actionLabel;
  };

  const loadGroups = () => {
    return Promise.resolve()
      .then(() => dataServiceContext.fetch("group/read", { sort: ["name"] }))
      .then((response) => {
        if (response.response.code == 200) {
          const groupsData = response.data.map((grp) => {
            return { ...grp, label: grp.name, value: grp.id };
          });

          setGroups([...groupsData]);
          return [...groupsData];
        }
      });
  };

  const loadData = (sortColumn) => {
    const sortColumnFld = sortColumn?.replace("-", "");

    //retrieve column definition for the sort column
    const columnDefObj = columnDefs.find(
      (col) => col.fldName === sortColumnFld
    );

    const defaultSort = SORT_COLUMN_MAP.derived_name_email;
    const sortOn = columnDefObj?.internalSort
      ? defaultSort
      : SORT_COLUMN_MAP[sortColumn] || [`${sortColumn}`];

    return Promise.resolve()
      .then(() => setListData(null))
      .then(() => !groups && loadGroups())
      .then(() =>
        dataServiceContext.fetch("user/read", {
          sort: sortOn,
        })
      )
      .then((response) => {
        setUsers([...response.data]);
        return response;
      });
  };

  const customActionHandler = (user) => {
    const actionType = getUserActionType(user);
    return Promise.resolve()
      .then(() => setCurrentUser({ ...user }))
      .then(() => confirmAction(user, actionType));
  };

  const confirmAction = (user, actionType) => {
    switch (actionType) {
      case USER_TABLE_ACTION_TYPE.RESETPWD:
      case USER_TABLE_ACTION_TYPE.REINVITE:
        const randPwd = UserMgmtUtils.generateRandomPassword();

        return Promise.resolve()

          .then(() =>
            dataServiceContext.fetch("user/update", {
              id: user.id,
              password: randPwd,
              force_password_change: true,
            })
          )
          .then((response) => {
            try {
              if (response.response.code === 200) {
                let responseData = [{ ...response.data[0], password: randPwd }];
                saveUserCallback(responseData, actionType);
              }
            } catch (err) {
              console.log("Err:" + err.message);
            }

            return response;
          });

      case USER_TABLE_ACTION_TYPE.DELETE:
        return Promise.resolve()
          .then(() => dataServiceContext.fetch("user/delete", { id: user.id }))
          .then((response) => {
            try {
              if (response.response.code === 200)
                userTableRef.current.loadTableData();
            } catch (err) {
              console.log("Err:" + err.message);
            }
            return response;
          });
      case USER_TABLE_ACTION_TYPE.ENABLE:
        return (
          Promise.resolve()
            // .then(() => this.setState({userAction: "EDIT"}))
            .then(() =>
              dataServiceContext.fetch("user/update", {
                id: user.id,
                disable: false,
              })
            )
            .then((response) => {
              try {
                if (response.response.code === 200)
                  userTableRef.current.loadTableData();
              } catch (err) {
                console.log("Err:" + err.message);
              }
              return response;
            })
        );
    }
  };

  const deleteUserAction = (user) => {
    return Promise.resolve().then(() =>
      confirmAction(user, USER_TABLE_ACTION_TYPE.DELETE)
    );
  };

  const editUserAction = (user) => {
    return Promise.resolve()
      .then(() => setCurrentUser({ ...user }))
      .then(() => userDialog.current.showUserDialog({ ...user }));
  };

  let rowActions = {};
  const isSsoEnabled = dataServiceContext.AccessManifest("isSsoEnabled");
  const canFullyAccessUser = !isSsoEnabled;
  if (canFullyAccessUser) {
    rowActions = {
      customAction: {
        width: "120px",
        icon: getUserCustomActionIcon,
        label: getUserCustomActionLabel,
        handler: customActionHandler,
        confirm: getCustomConfirmObj,
      },
      edit: {
        isAllowed: (user) => isUserDeletable(user),
        handler: editUserAction,
      },
      delete: {
        isAllowed: (user) => isUserDeletable(user),
        handler: deleteUserAction,
        confirm: getDeleteConfirmObj,
      },
    };
  }

  const prepareAndShowInviteUserDialog = (userData) => {
    return Promise.resolve()
      .then(() => setInviteUserPassword(userData[0].password))
      .then(() => setCurrentUser({ ...userData[0] }))
      .then(() => inviteUserDialog.current.showDialog());
    //  .then(() => inviteUserDialog.current.showDialog());
  };

  //userData returned in an array
  const saveUserCallback = (userData, actionType) => {
    return Promise.resolve()
      .then(() => userTableRef.current.loadTableData())
      .then(() => setCurrentUser({ ...userData[0] }))
      .then(() => {
        let isInviteUserFlow = false;
        if (actionType === "ADD" || userData[0].force_password_change) {
          isInviteUserFlow = true;
        }

        if (isInviteUserFlow) {
          return prepareAndShowInviteUserDialog(userData);
        }
      });
  };

  return (
    <>
      <TableCrud
        ref={userTableRef}
        loadData={loadData}
        listData={listData}
        columnDefs={columnDefs}
        rowActions={rowActions}
        maxHeight={"calc(100vh - 100px)"}
      />
      <DialogUser
        action={"EDIT"}
        ref={userDialog}
        userObj={currentUser}
        updateCallback={saveUserCallback}
      />
      <DialogInviteUser
        userObj={currentUser}
        randPassword={inviteUserPassword}
        ref={inviteUserDialog}
      />
    </>
  );
});

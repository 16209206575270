import {commonSection, detectionSectionTop, outEnabled} from "./CommonFields";
import { rebuildSection} from "./IntegrationDefinitionUtils";
import {IntegrationCategories} from "./IntegrationConsts";

//--------------------------------------------------------------
// email
//--------------------------------------------------------------
export const email ={
    common: rebuildSection( commonSection, {
        category: IntegrationCategories.NOTIFICATIONS,
        integration: "email",
        out_event_type:  "zebrium_incident,zebrium_email",
        auth_scheme: "none",
        out_alert_on_new_incidents: true
    }),
    notification: {
        top: [outEnabled],
        emailConfig: [{
            field_name: "emailAddressList",
            required: true,
            label: "Email Address List",
            db_field_name: "out_email_list",
            field_type: "textarea",
            fieldWidth:"400px"
        },
            ...detectionSectionTop],
        order: ["top", "emailConfig"]  //ordering of ui sections
    }
}
const encodeKey = "thisissecret1234ABCDthisissecret1234ABCDEFGHIJKLMNOPQRSTUVWXYZthisissecret12345";

const UserMgmtUtils = {
    randomGen(min, max) {
        //The maximum is inclusive and the minimum is inclusive
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1) + min);
    },

    generateRandomPassword() {

        const AllCategory = {
            cat0: "0123456789",
            cat1: "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~",
            cat2: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
            cat3:  "acdefghijklnopqrstuvwxyz"
        }

        //password is between 8 ~ 16 long
        const passwordLength = this.randomGen(8, 16)
        const numOfCats = Object.keys(AllCategory).length

        let password = new Array(passwordLength);

        //assign from each cat to password random position, if already assign, get a new position
        for( let c=0; c<numOfCats;c++ ) {
            let position = this.randomGen(0, passwordLength-1);
            //finding next empty spot
            while( password[position] ) {

                position = this.randomGen(0, passwordLength-1);
            }

            const category = 'cat'+c;
            const catCharLength = AllCategory[category].length;
            const randomCatPosition= this.randomGen(0, catCharLength-1);
            password[position] = AllCategory[category][randomCatPosition]
        }

        //now password contains one char from each category
        for( let p=0; p<passwordLength; p++ ) {
            //for each position not been assigned
            if( !password[p] ){
                //get random category
                const randomCat = this.randomGen(0, numOfCats-1);
                const category = 'cat'+randomCat;
                const catCharLength = AllCategory[category].length;


                //get random category position
                const randomCatPos = this.randomGen(0, catCharLength-1);
                password[p] = AllCategory[category][randomCatPos]
            }

        }
        return password.join('');
    },
    encodeParam( origVal ) {
        let outVal = "";

        const len = (origVal||"").length;
        for(let i=0; i<len; i++) {
            outVal += String.fromCharCode(origVal[i].charCodeAt(0) ^ encodeKey[i].charCodeAt(0));
        }
        return btoa(outVal);
    },
    decodeParam( inStr ) {
        let outVal = "";
        let origVal = decodeURIComponent(inStr);
        origVal = atob(origVal);

        const len = origVal.length;
        for (let i = 0; i < len; i++) {
            outVal += String.fromCharCode(origVal[i].charCodeAt(0) ^ encodeKey[i].charCodeAt(0));
        }
        return outVal;
    }
}

export default UserMgmtUtils;

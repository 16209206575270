import React, {
  useContext,
  useState,
  useEffect,
} from "react";
import {
  Row,
  Button,
  Field,
  FieldTypes,
  DataServiceContext,
  useModalDialog,
} from "athena-next-ui-lib";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export function TokenGen(props) {
  const dataServiceContext = useContext(DataServiceContext);
  const { openConfirmationDialog } = useModalDialog();

  const [token, setToken] = useState(props.accessToken);

  useEffect(() => {
    if (props.accesssToken) {
      setToken(props.accesssToken);
    }
  }, [props.accesssToken]);

  function regenToken() {
    return Promise.resolve()
      .then(() =>
      props?.showParentDialog && props?.showParentDialog({
          ...props.origData,
          token: "",
          regenerating: true,
        })
      )
      .then(() =>
        dataServiceContext.fetch("accesstoken/update/tokengen", {
          token: token,
        })
      )
      .then((response) => {
        if (response.response.code == 200) {
          setToken(response.data[0].token);
        } else {
          throw response.response;
        }

        return response;
      })
      .then((dataOut) =>
        props.updateCallback({ ...dataOut?.data?.[0], regenerating: false })
      )
      .catch((err) => {
        throw err;
      });
  }

  const showConfirmDialog = () => {
    return (
      Promise.resolve()
        // .then(()=>closeModalDialog())
        .then(() =>
          openConfirmationDialog({
            icon: faQuestionCircle,
            submitLabel: "Continue",
            title: "Token Regen",
            content: "Are you sure you want to regen token?",
            closeOnSubmit: false,
            submit: () => {
              // will open edit incident type dialog
              regenToken();
            },
          })
        )
    );
  };

  return (
    <>
      <Row>
        <Field {...props} type={FieldTypes.COPY} value={token} height={60} />
        <span style={{ margin: "8px 8px" }}>
          <Button
            onClick={() => {
              showConfirmDialog();
            }}
            processing={props.regenerating}
          >
            Regenerate
          </Button>
        </span>
      </Row>
    </>
  );
}

import {commonSection, detectionSectionTop, outEnabled} from "./CommonFields";
import {rebuildSection} from "./IntegrationDefinitionUtils";
import {IntegrationCategories} from "./IntegrationConsts";

//--------------------------------------------------------------
// mattermost
//--------------------------------------------------------------
export const mattermost ={
    common: rebuildSection( commonSection, {
        category: IntegrationCategories.NOTIFICATIONS,
        integration: "mattermost",
        out_event_type:  "zebrium_incident,zebrium_mattermost",
        out_alert_on_new_incidents: true
    }),
    notification: {
        top: [outEnabled],
        mattermostConfig: [{
            field_name: "webhookURL",
            required: true,
            label: "Webhook URL",
            db_field_name: "out_webhook",
            field_type: "text",
        },
            ...detectionSectionTop],
        order: ["top", "mattermostConfig"]  //ordering of ui sections
    }
}
import {
  commonSection,
  detectionSectionTop,
  outEnabled,
} from "./CommonFields";
import { rebuildSection } from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";

//--------------------------------------------------------------
// teams
//--------------------------------------------------------------
export const teams = {
  common: rebuildSection(commonSection, {
    category: IntegrationCategories.NOTIFICATIONS,
    integration: "teams",
    out_event_type: "zebrium_incident,zebrium_teams",
    out_alert_on_new_incidents: true,
  }),
  notification: {
    top: [outEnabled],
    teamsConfig: [
      {
        field_name: "webhookURL",
        required: true,
        label: "Webhook URL",
        db_field_name: "out_webhook",
        field_type: "text",
      },
      ...detectionSectionTop,
    ],
    order: ["top", "teamsConfig"], //ordering of ui sections
  },
};

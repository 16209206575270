import React, { useContext, useState, useEffect } from "react";

import {
  Button,
  Column,
  DataServiceContext,
  Field,
  Panel,
  Row,
  Toolbar,
  useModalDialog,
} from "athena-next-ui-lib";
import styles from "/pages/auth/report-settings.module.scss";
import { faCog } from "@fortawesome/free-solid-svg-icons";

const buttonGroupList = {
  high_volume_period: [
    {
      label: "hour",
      value: "hour",
      tooltip: "Alert at most 1 per log stream per hour",
      tooltipPosition: "southEast",
    },
    {
      label: "minute",
      value: "minute",
      tooltip: "Alert at most 1 per log stream per minute",
      tooltipPosition: "southEast",
    },
  ],
};
export const RcBakeParams = (props) => {
  const {
    title,
    desc,
    field: fieldName,
    fieldType,
    numberOfColumns,
    range,
    valueType,
    origBakeParams: bakeParamsFromParent,
    bakeParamsDefaults,
  } = props;

  const dataServiceContext = useContext(DataServiceContext);
  const { openConfirmationDialog, closeModalDialog } = useModalDialog();

  const [processing, setProcessing] = useState(false);
  const [valueModified, setValueModified] = useState(false);

  const [bakeParams, setBakeParams] = useState({ ...bakeParamsFromParent }); //stored values
  const [origBakeParams, setOrigBakeParams] = useState({
    ...bakeParamsFromParent,
  }); //user changes

  const [isDiffFromDefault, setDiffFromDefault] = useState(false);
  const [otherErrorMessage, setOtherErrorMessage] = useState(null); //stored values
  const [secondaryProcessing, setSecondaryProcessing] = useState(false);
  const [clickApplyHistoric, setClickApplyHistoric] = useState(false);

  const panelCSS = isDiffFromDefault ? "highlight" : "";
  const width = "calc(" + Math.round(100 / numberOfColumns) + "% - "+Math.round(80/numberOfColumns)+"px)";

  useEffect(() => {
    if (
      bakeParams?.[fieldName] == undefined ||
      origBakeParams?.[fieldName] == undefined
    ) {
      //skip if value not been set
      return;
    }

    if (bakeParams?.[fieldName] !== origBakeParams?.[fieldName]) {
      setValueModified(true);
    } else {
      setValueModified(false);
    }

    if (origBakeParams?.[fieldName] !== bakeParamsDefaults?.[fieldName]) {
      setDiffFromDefault(true);
    } else {
      setDiffFromDefault(false);
    }
  }, [bakeParams, origBakeParams, bakeParamsDefaults]);

  useEffect(() => {
    if (clickApplyHistoric) {
      showApplyTimeRangeAndHistoricDialog();
    }
  }, [secondaryProcessing, otherErrorMessage]);

  const applyTimeRangeAndHistoricSettingFieldChange = () => {
    const deploymentId = bakeParams.deployment_id;
    const timeRangePayload = {
      rc_time_range: "all-time",
    };

    const historicPayload = {
      deployment_id: deploymentId,
      historic_inci: bakeParams?.["historic_inci"],
    };

    return Promise.resolve()
      .then(() =>  setProcessing(true))
      .then(() =>
        dataServiceContext.fetch("bakeparams/create", historicPayload)
      )
      .then((historicCall) => {
        if (historicCall.response.code === 200) {
          const bakeParamsData = (historicCall?.data || [])[0];
          setBakeParams({ ...bakeParamsData });
          setOrigBakeParams({ ...bakeParamsData });
        } else {
          const errMessage = `Unable to set ${title}.  ${historicCall.response.message}`;
          throw new Error(errMessage);
        }
        return true;
      })
      .then(() =>
        dataServiceContext.fetch("accountsetting/create", timeRangePayload)
      )
      .then((timerangeCall) => {
        if (timerangeCall.response.code === 200) {
          const accountSettingData = (timerangeCall?.data || [])[0];
          props.setOrigAccountSettingData({ ...accountSettingData });
        } else {
          const errMessage = `Unable to set ${title}.  ${timerangeCall.response.message}`;
          throw new Error(errMessage);
        }
        return true;
      })
      .then((successFlag) => {
        if (successFlag) {
          closeModalDialog();
        }
        return successFlag;
      })
      .finally(() => setProcessing(false));
  };

  const applyHistoricSettingFieldChange = () => {
    const deploymentId = bakeParams.deployment_id;
    const historicPayload = {
      deployment_id: deploymentId,
      historic_inci: bakeParams?.["historic_inci"],
    };

    return Promise.resolve()
      .then(() => {
        setClickApplyHistoric(true);
        setSecondaryProcessing(true);
        return true;
      })
      .then(() =>
        dataServiceContext.fetch("bakeparams/create", historicPayload)
      )
      .then((historicCall) => {
        if (historicCall.response.code === 200) {
          const bakeParamsData = (historicCall?.data || [])[0];
          setBakeParams({ ...bakeParamsData });
          setOrigBakeParams({ ...bakeParamsData });
          return true;
        } else {
          const errMessage = `Unable to set ${title}.  ${historicCall.response.message}`;
          setOtherErrorMessage(errMessage);
          return false;
        }
      })
      .then((successFlag) => {
        if (successFlag) {
          setClickApplyHistoric(false);
          closeModalDialog();
        }
        return successFlag;
      })
      .catch((err) => {
        throw err;
      })
      .finally(() => {
        setSecondaryProcessing(false);
        setClickApplyHistoric(false);
        return true;
      });
  };

  const drawApplyHistoricSettingButton = () => {
    return (
      <div style={{ marginRight: "8px" }}>
        <Button
          type="secondary"
          disabled={secondaryProcessing}
          processing={secondaryProcessing}
          onClick={applyHistoricSettingFieldChange}
        >
          Set Historic Incident Detection Only
        </Button>
      </div>
    );
  };

  const showApplyTimeRangeAndHistoricDialog = () => {
    openConfirmationDialog({
      title: "Apply Change?",
      icon: faCog,
      maxWidth: "800px",
      closeOnSubmit: false,
      cancelCallback: () => {
        setOtherErrorMessage(null);
      },
      submitLabel: "Set Time Range & Historic Incident Detection",
      secondaryButtons: drawApplyHistoricSettingButton(),
      submit: applyTimeRangeAndHistoricSettingFieldChange,
      otherErrorMessage: otherErrorMessage,
      content: (
        <Column>
          <span>
            Are you sure you want to change the setting Enable Historic Incident
            Detection to <b>YES</b>? If you do, we recommend changing the
            default time range to <b>"All Time"</b>
          </span>
        </Column>
      ),
    });
  };

  const applyHandler = () => {
    if (
      fieldName === "historic_inci" &&
      bakeParams?.[fieldName] === true &&
      props.origAccountSettingData.rc_time_range !== "all-time"
    ) {
      showApplyTimeRangeAndHistoricDialog();
    } else {
      openConfirmationDialog({
        title: "Apply Change",
        icon: faCog,
        closeOnSubmit: false,
        submit: () => updateBakeParams(bakeParams[fieldName]),
        content: (
          <Column>
            <span>Are you sure you want to change the setting:</span>
            <span>
              <b>{title}</b>?
            </span>{" "}
          </Column>
        ),
      });
    }
  };

  const undoHandler = () => {
    return setBakeParams({ ...bakeParams, [fieldName]: origBakeParams?.[fieldName] });
  };

  const resetToDefault = () => {
    return updateBakeParams(bakeParamsDefaults[fieldName]);
  };

  const updateBakeParams = (newValue) => {
    const deploymentId = bakeParams.deployment_id;

    let payload = {
      deployment_id: deploymentId,
      [fieldName]: newValue,
    };

    return Promise.resolve()
      .then(() => setProcessing(true))
      .then(() => dataServiceContext.fetch("bakeparams/create", payload))
      .then((bakepramsCall) => {
        if (bakepramsCall.response.code === 200) {
          const bakeParamsData = (bakepramsCall.data || [])[0];
          setBakeParams({ ...bakeParamsData });
          setOrigBakeParams({ ...bakeParamsData });
          return true;
        } else {
          const errMessage = `Unable to set ${title}.  ${bakepramsCall.response.message}`;
          throw new Error(errMessage);
        }
      })
      .then((successFlag) => {
        if (successFlag) {
          closeModalDialog();
        }
        return successFlag;
      })
      .finally(() => setProcessing(false));
  };

  const drawBakeParamField = () => {
    if (Object.keys(bakeParamsDefaults || {}).length < 1) return;

    let hint =
      typeof range == "object"
        ? "range: " +
          range.min.toLocaleString() +
          " to " +
          range.max.toLocaleString()
        : range;

    if (valueType == "numeric" && typeof range == "object") {
      //need to show decimal in hint
      hint =
        "range: " +
        Number.parseFloat(range.min).toFixed(1) +
        " to " +
        Number.parseFloat(range.max).toFixed(1);
    }
    const returnType =
      ["bigint", "numeric"].indexOf(valueType) > -1 ? "number" : "string";

    let valueInRange = true;
    let fieldProps = null;
    if (typeof range == "object") {
      fieldProps = {
        ...fieldProps,
        min: range.min,
        max: range.max,
        width: "100px",
      };

      valueInRange =
        bakeParams[fieldName] >= range.min &&
        bakeParams[fieldName] <= range.max;
    }

    let field = null;
    if (valueType === "boolean") {
      const btnNoYes = [
        { label: props.labels.no || "No", value: false },
        {
          label: props.labels.yes || "Yes",
          value: true,
        },
      ];
      const defaultButton =
        btnNoYes.find((item) => item.value == bakeParams?.[fieldName]) ||
        btnNoYes.find((item) => item.value == bakeParamsDefaults?.[fieldName]);

      field = (
        <Field
          type={"button-group"}
          name={"enabled"}
          css={["md", "dark"]}
          values={btnNoYes}
          value={defaultButton}
          onUpdateValue={(selObj) => {
            if (selObj.value !== defaultButton.value) {
              setBakeParams({ ...bakeParams, [fieldName]: selObj.value });
            }
          }}
        />
      );
    } else if (fieldType === "button-group") {
      const btnList = buttonGroupList[fieldName];
      let btnValue = btnList.find(
        (item) => item.value === bakeParams?.[fieldName]
      );
      if (["", undefined].includes(btnValue)) {
        btnValue = bakeParamsDefaults?.[fieldName];
      }

      field = (
        <Field
          type={fieldType}
          name={fieldName}
          css={["md", "dark"]}
          values={btnList}
          value={btnValue}
          onUpdateValue={(selObj) => {
            if (selObj.value !== btnValue.value) {
              setBakeParams({ ...bakeParams, [fieldName]: selObj.value });
            }
          }}
        />
      );
    } else {
      field = (
        <Field
          {...fieldProps}
          type={fieldType}
          returnType={returnType}
          hint={hint}
          width={"200px"}
          onUpdateValue={(value) => {
            setBakeParams({ ...bakeParams, [fieldName]: value });
          }}
          value={bakeParams[fieldName]}
        />
      );
    }

    return (
      <div className={styles.fieldRow}>
        {field}
        {valueModified && (
          <div style={{ marginLeft: "8px" }}>
            <Button
              type={"primary"}
              processing={processing}
              disabled={!valueInRange || processing}
              onClick={applyHandler}
            >
              Apply
            </Button>
          </div>
        )}
        {valueModified && (
          <div style={{ marginLeft: "8px" }}>
            {" "}
            <Button
              type={"secondary"}
              disabled={processing}
              onClick={undoHandler}
            >
              Undo
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <Panel width={width} height={"auto"} style={panelCSS}>
      <Toolbar title={title}>
        {isDiffFromDefault && (
          <Button
            type={"secondary"}
            size={"sm"}
            disabled={processing}
            processing={processing}
            onClick={resetToDefault}
          >
            Restore Default
          </Button>
        )}
      </Toolbar>

      <Row>
        <Column>{drawBakeParamField()}</Column>
      </Row>
      <Row>
        <div className={styles.explanation}>{desc}</div>
      </Row>
    </Panel>
  );
};

RcBakeParams.getInitialProps = async (ctx) => {
  return {};
};

import {  Field} from "athena-next-ui-lib";

const width = "550px";
const maxWidth = "550px";


const openHelp = (link)=>{
    window.open( link,"zebrium-help", 'toolbars=0,width=640,height=480,left=200,top=200,scrollbars=1,resizable=1');
}

const CollectorContents = (ZAPI_TOKEN, ZAPI_URL, RCSettingsURL)=>{
    return [{
        id: "azure",
        content: <>
            <Field type={"copy"} size="sm" label={"ZE_LOG_COLLECTOR_URL"}
                    labelAlign={"top"}
                    value={ZAPI_URL} height={30}
                    fieldWidth={width}
                    maxWidth={maxWidth}/>
            <Field type={"copy"} size="sm"
                    label={"ZE_LOG_COLLECTOR_TOKEN"}
                    labelAlign={"top"}
                    value={ZAPI_TOKEN} height={30}
                    fieldWidth={width}
                    maxWidth={maxWidth}/>
            <p>Instructions to install the Azure Monitor OTEL collector can be found <a href="https://docs.zebrium.com/docs/setup/azure_monitor_otel/" target={"_blank"} className={'link'}>here</a>.</p>
        </>
    }, {
        id: "cloudwatch",
        content: <>
            <Field type={"copy"} size="sm" label={"ZE_LOG_COLLECTOR_URL"}
                    labelAlign={"top"}
                    value={ZAPI_URL} height={30}
                    fieldWidth={width}
                    maxWidth={maxWidth}/>
            <Field type={"copy"} size="sm"
                    label={"ZE_LOG_COLLECTOR_TOKEN"}
                    labelAlign={"top"}
                    value={ZAPI_TOKEN} height={30}
                    fieldWidth={width}
                    maxWidth={maxWidth}/>
            <p>Instructions to install the AWS CloudWatch OTEL collector can be found <a href="https://docs.zebrium.com/docs/setup/cloudwatch_otel/" target={"_blank"} className={'link'}>here</a>.</p>
        </>
    }, {
        id: "kubernetes-helm-3",
        content: <>
            <Field
                type={"copy"}
                label={"Copy this HELM3 command and run it in terminal"}
                labelAlign={"top"}
                size={"sm"}
                value={`helm upgrade -i zlog-collector zlog-collector --namespace zebrium --create-namespace --repo https://charts.zebrium.com --set zebrium.collectorUrl=${ZAPI_URL},zebrium.authToken=${ZAPI_TOKEN},zebrium.timezone=KUBERNETES_HOST_TIMEZONE,zebrium.deployment=YOUR_SERVICE_GROUP`}
                height={120}
                fieldWidth={width}
                maxWidth={maxWidth}/>
            <p>KUBERNETES_HOST_TIMEZONE is the timezone setting on kubernetes host, for example, "UTC" or
                "America/Los_Angeles". If this option is not provided, default value UTC will be used.</p>
            <p>YOUR_SERVICE_GROUP defines a failure domain boundary for anomaly correlation. This allows you to
                collect logs from multiple applications and isolate the logs of one application from another
                so as not to mix these in a Root Cause Report. This is referred to as Service Groups in the Zebrium UI.</p>
            {/*<p>Detailed instructions for HELM 2 & 3 commands can be found here:<br/>*/}
            {/*    <a href="https://docs.zebrium.com/docs/setup/kubernetes"*/}
            {/*       target={"_blank"}>https://docs.zebrium.com/docs/setup/kubernetes</a></p>*/}
        </>
    }, {
        id: "linux",
        content: <>
            <Field
                label={"Copy this command and run it in terminal"}
                labelAlign={"top"}
                type={"copy"}
                size={"sm"}
                value={`curl https://raw.githubusercontent.com/zebrium/ze-fluentd-plugin/master/install_collector.sh | ZE_LOG_COLLECTOR_URL=${ZAPI_URL} ZE_LOG_COLLECTOR_TOKEN=${ZAPI_TOKEN} ZE_HOST_TAGS="ze_deployment_name=YOUR_SERVICE_GROUP"  /bin/bash `}
                height={120}
                fieldWidth={width}
                maxWidth={maxWidth}/>
            <p>YOUR_SERVICE_GROUP defines a failure domain boundary for anomaly correlation. This allows you to
                collect logs from multiple applications and isolate the logs of one application from another
                so as not to mix these in a Root Cause Report. This is referred to as Service Groups in the Zebrium UI.</p>
            {/*<p>Detailed instructions can be found here:<br/>*/}
            {/*    <a href="https://docs.zebrium.com/docs/setup/linux"*/}
            {/*       target={"_blank"}>https://docs.zebrium.com/docs/setup/linux</a></p>*/}
        </>
    }, {
        id: "vsphere",
        content: <>
            <Field type={"copy"} size="sm" label={"ZE_LOG_COLLECTOR_URL"}
                   labelAlign={"top"}
                   value={ZAPI_URL} height={30}
                   fieldWidth={width}
                   maxWidth={maxWidth}/>
            <Field type={"copy"} size="sm"
                   label={"ZE_LOG_COLLECTOR_TOKEN"}
                   labelAlign={"top"}
                   value={ZAPI_TOKEN} height={30}
                   fieldWidth={width}
                   maxWidth={maxWidth}/>
            <p>Instructions to install the VMware vSphere can be found <a href="https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/vmware_vsphere.html" target={"_blank"} className={'link'}>here</a>.</p>
        </>
    }, {
        id: "windows",
        content: <>
            <Field type={"copy"} size="sm" label={"ZE_LOG_COLLECTOR_URL"}
                   labelAlign={"top"}
                   value={ZAPI_URL} height={30}
                   fieldWidth={width}
                   maxWidth={maxWidth}/>
            <Field type={"copy"} size="sm"
                   label={"ZE_LOG_COLLECTOR_TOKEN"}
                   labelAlign={"top"}
                   value={ZAPI_TOKEN} height={30}
                   fieldWidth={width}
                   maxWidth={maxWidth}/>
            <p>Instructions to install the Windows OTEL collector can be found <a href="https://docs.zebrium.com/docs/setup/windows_otel/" target={"_blank"} className={'link'}>here</a>.</p>
        </>
    }, {
        id: "file-upload",
        content: <>
            <p>The ze utility is Zebrium's command line interface for uploading log events from files or streams.</p>
            <p>You can download ze from the Zebrium GitHub repository here: <a href="https://github.com/zebrium/ze-cli" target={"_blank"}>https://github.com/zebrium/ze-cli</a></p>
            <p>The only configuration necessary is to add your ZE_LOG_COLLECTOR_URL and ZE_LOG_COLLECTOR_TOKEN to the .ze configuration file.</p>
            <p><b>Note:</b> You must Enable Historic Incident Detection in the <a href={RCSettingsURL}
                                                                                  target={"_blank"}>Root Cause Settings</a> prior to uploading log files with timestamps from the past.</p>

            <Field
                label={"Copy these settings and paste into your $HOME/.ze file"}
                labelAlign={"top"}
                type={"copy"}
                size={"sm"}
                value={`url: ${ZAPI_URL} \r\nauth: ${ZAPI_TOKEN}`}
                height={100}
                fieldWidth={width}
                maxWidth={maxWidth}/>
            {/*<p>Detailed instructions can be found here:<br/>*/}
            {/*    <a href="https://docs.zebrium.com/docs/setup/file_upload"*/}
            {/*       target={"_blank"}>https://docs.zebrium.com/docs/setup/file_upload</a></p>*/}
        </>
    },{
        id: "other-collectors",
        content: <>
            <Field type={"copy"} size="sm" label={"ZE_LOG_COLLECTOR_URL"}
                   labelAlign={"top"}
                   value={ZAPI_URL} height={30}
                   fieldWidth={width}
                   maxWidth={maxWidth}/>
            <Field type={"copy"} size="sm"
                   label={"ZE_LOG_COLLECTOR_TOKEN"}
                   labelAlign={"top"}
                   value={ZAPI_TOKEN} height={30}
                   fieldWidth={width}
                   maxWidth={maxWidth}/>
            <p>Detailed instructions for other supported collectors can be found here:<br/>

                <Field type={"link"}
                       onClick={()=>openHelp("https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/CloudWatch_Collectors.html")}
                       linkLabel={"CloudWatch"}/>

                <Field type={"link"}
                       onClick={()=>openHelp("https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/Docker_ECS_Collectors.html")}
                       linkLabel={"Docker (including ECS)"}/>

                <Field type={"link"}
                       onClick={()=>openHelp("https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/Logstash_Collectors.html")}
                       linkLabel={"Logstash"}/>

                <Field type={"link"}
                       onClick={()=>openHelp("https://docs.sciencelogic.com/zebrium/latest/Content/Web_Zebrium/03_Log_Collectors_Uploads/Syslog_Forwarder.html")}
                       linkLabel={"Syslog Forwarder"}/>

            </p>
        </>
    }]
}


export const LogCollectorUtils = {
    getCollectorByID:(collectorID, ZAPI_TOKEN, ZAPI_URL,RCSettingsURL )=>{
        const allContent = CollectorContents( ZAPI_TOKEN, ZAPI_URL, RCSettingsURL);
        const matchingCollector = allContent.find(collector=>collector.id===collectorID);
        return matchingCollector?.content || null;
    }
}

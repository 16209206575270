import React, { useState } from "react";

import { TableCrud, tooltipStyles } from "athena-next-ui-lib";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

export const RoleTable = (props) => {
  const [roles, setRoles] = useState(null);

  const colWidth = "200px";

  const getRoleFeatureDisplay = (row, colDef) => {
    if (row[colDef.fldName]) {
      return <FontAwesomeIcon icon={faCheck} size={"lg"} />;
    } else {
      return null;
    }
  };

  const rowActions = {};
  const columnDefs = [
    { label: "Feature", fldName: "feature", width: "15%" },
    {
      label: "Owner",
      align: "center",
      fldName: "owner",
      renderer: getRoleFeatureDisplay,
      width: colWidth,
    },
    {
      label: "Admin",
      align: "center",
      fldName: "admin",
      renderer: getRoleFeatureDisplay,
      width: colWidth,
    },
    {
      label: "Editor",
      align: "center",
      fldName: "editor",
      renderer: getRoleFeatureDisplay,
      width: colWidth,
    },
    {
      label: "Viewer",
      align: "center",
      fldName: "viewer",
      renderer: getRoleFeatureDisplay,
      width: colWidth,
    },
    { label: "", align: "center", width: "60%" },
  ];

  const deriveTooltip = (text, tooltip) => {
    return (
      <div
        className={`${tooltipStyles.cssTooltip} ${tooltipStyles.cssTooltipEast}`}
      >
        {text}
        <div className={tooltipStyles.cssTooltipText}>{tooltip}</div>
      </div>
    );
  };

  const loadData = () => {
    return Promise.resolve().then(() => {
      const rolesData = [
        {
          feature: deriveTooltip(
            "User Management",
            "Add and delete users and define groups"
          ),
          owner: true,
          admin: false,
          editor: false,
          viewer: false,
        },
        {
          feature: deriveTooltip("Root Cause Settings", "Fine tune Zebrium ML"),
          owner: true,
          admin: true,
          editor: false,
          viewer: false,
        },
        {
          feature: "Integrations",
          owner: true,
          admin: true,
          editor: false,
          viewer: false,
        },
        {
          feature: "Log Collector",
          owner: true,
          admin: true,
          editor: false,
          viewer: false,
        },
        {
          feature: deriveTooltip(
            "Define Custom Alert Types",
            "Define and manage custom Alert Types that Zebrium ML uses for root cause detection"
          ),
          owner: true,
          admin: true,
          editor: false,
          viewer: false,
        },
        {
          feature: deriveTooltip(
            "Edit Report Notes and Alerting",
            "Add explanatory notes on reports and define when and who should be alerted in future "
          ),
          owner: true,
          admin: true,
          editor: true,
          viewer: false,
        },
        {
          feature: "View Report Notes and Alerting",
          owner: true,
          admin: true,
          editor: true,
          viewer: true,
        },
      ];
      const reversedRolesData = [...rolesData].reverse();
      return setRoles(reversedRolesData);
    });
  };

  const drawRoleTable = () => {
    return (
      <TableCrud
        loadData={loadData}
        listData={roles || null}
        columnDefs={columnDefs}
        rowActions={rowActions}
        maxHeight={"calc(100vh - 100px)"}
      ></TableCrud>
    );
  };

  return <>{props.viewType == "role" && drawRoleTable()}</>;
};

import {signalSectionBottom, commonSection, inEnabled, outEnabled} from "./CommonFields";
import { rebuildSection} from "./IntegrationDefinitionUtils";
import {INTEGRATION_TEST_TYPES, IntegrationCategories} from "./IntegrationConsts";
//--------------------------------------------------------------
// dynatrace
//--------------------------------------------------------------
export const dynatrace ={
    common: rebuildSection( commonSection, {
        integration: "dynatrace",
        category: IntegrationCategories.OBSERVABILITY_DASHBOARD,
        out_event_type:  "zebrium_incident,zebrium_monitor_metrics",
        auth_scheme: "token"
    }),
    signal : {
        hideOnCreate: true,
        top:  [inEnabled, {
            field_name: "tabTitle",
            field_type: "description",
            value: "Dynatrace Alert Policies can be configured to send signals to Zebrium. Zebrium will search for root cause indicators in your logs around the time of the signal. If found, Zebrium will send the results as a payload to Dynatrace. "
        }],
        APIConfig: [ {
            field_name: "baseUrl",
            required: true,
            label: "Base URL",
            db_field_name: "base_url",
            field_type: "text",
        }, {
            field_name: "entityId",
            required: true,
            label: "Entity Id",
            db_field_name: "resource_id",
            field_type: "text",
        }, {
            field_name: "accessToken",
            required: true,
            label: "Access Token",
            db_field_name: "auth_token",
            field_type: "text"
        }],
        bottom: signalSectionBottom,
        order: ["top", "APIConfig", "bottom"]  //ordering of ui sections
    },
    detection: [
        outEnabled, {
            field_name: "tabTitle",
            field_type: "description",
            value:"Detections occur whenever Zebrium finds a correlated set of root cause indicators in the logs (these form a root cause report summary). Detections are sent as payloads to Dynatrace.",
        },{
        field_name: "baseUrl",
        required: true,
        label: "Base URL",
        db_field_name: "base_url",
        field_type: "text",
    }, {
        field_name: "entityId",
        required: true,
        label: "Entity Id",
        db_field_name: "resource_id",
        field_type: "text",
    }, {
        field_name: "apiKey",
        required: true,
        label: "Access Token",
        db_field_name: "auth_password",
        field_type: "text",
    },{
        field_name: "test-integration",
        required: true,
        label: "Create Sample Alert",
        test_type: INTEGRATION_TEST_TYPES.CONNECT_AND_CREATE,
        field_type: "button",
        tooltip: "Create an API key under Organization Settings in Datadog."
    }]
}
import React, {
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  DataServiceContext,
  getErrorMessageDisplay,
  useModalDialog,
} from "athena-next-ui-lib";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GroupForm } from "./GroupForm";

export const DialogGroup = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog } = useModalDialog();

  const isAdd = props.action === "ADD";

  const groupFormRef = useRef();

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const getGroupForm = () => {
    const groupData = { ...props.groupObj, channels: ["222", "333"] };

    return (
      <GroupForm
        form={groupFormRef}
        action={props.action}
        processing={false}
        data={groupData}
      />
    );
  };

  const showDialog = (groupData) => {
    const title = isAdd ? "Add Group" : "Edit Group";
    const buttonLabel = isAdd ? "Add" : "Update";

    const saveGroup = () => {
      let values = groupFormRef.current.getValues();

      if (values.errors.length > 0) {
        //form contains error(s), throw error
        const err = { message: getErrorMessageDisplay(values.errors) };
        throw err;
      }

      const deploymentIDs = values.deployment_ids;
      if (deploymentIDs?.length > 0) {
        values.deployment_ids = values.deployment_ids.split(",");
      } else {
        values.deployment_ids = [];
      }

      const isAdd = props.action === "ADD";

      if (isAdd) {
        values = {
          ...values,
        };
      } else {
        //in edit mode

        values = { ...values, id: groupData.id };
      }

      const apiUrl = isAdd ? "group/create" : "group/update";

      return Promise.resolve()
        .then(() => dataServiceContext.fetch(apiUrl, values))
        .then((response) => {
          if (response.response.code === 200) {
            props?.updateCallback?.();
          } else {
            const err = { message: response.response.message };
            throw err;
          }
        });
    };

    const modalDialogSettings = {
      title: title,
      icon: faUsers,
      submitLabel: buttonLabel,
      submit: saveGroup,
      content: getGroupForm(),
    };

    return Promise.resolve().then(() => openModalDialog(modalDialogSettings));
  };

  if (isAdd) {
    return (
      <Button
        onClick={() => {
          showDialog({});
        }}
        processing={false}
      >
        <FontAwesomeIcon icon={faUsers} />
        <span>Add Group</span>
      </Button>
    );
  } else {
    return null;
  }
});

import React, {
  useState,
  useEffect,
  useContext,
  forwardRef,
} from "react";

import { DataServiceContext, Field, FieldTypes } from "athena-next-ui-lib";

/***************************************************************
 * this component is used in group dialog, multiSelect, with All
 ****************************************************************/

export const GroupDeploymentSelector = forwardRef((props, ref) => {
  const dataServiceContext = useContext(DataServiceContext);
  const { deploymentSelectorRef, selectedDeployments } = props;

  const [selectedValues, setSelectedValues] = useState(selectedDeployments);
  const [deployments, setDeployments] = useState(null);

  const [response, setResponse] = useState({ code: 0 });

  const isOwnerRole = dataServiceContext.getUserProfile().role == "owner";

  const defaultContentProps = {
    ...props,
    labelWidth: "105px",
    fieldWidth: "200px",
    type: FieldTypes.MULTI_SELECT,
    truncateDisplayBeyond: 30,
    ref: deploymentSelectorRef,
    value: selectedValues,
    values: deployments,
    response: response,
    allowSelectAll: true,
    selectAllLabel: "All",
  };

  const [content, setContent] = useState(<Field {...defaultContentProps} />);

  useEffect(() => {
    if (deployments && response.code != 0) {
      setContent(<Field {...defaultContentProps} response={response} />);
    }
  }, [deployments, response]);

  useEffect(() => {
    setContent(
      <Field
        {...defaultContentProps}
        value={selectedValues}
        response={response}
      />
    );
  }, [selectedValues, props.disabled]);

  useEffect(() => {
    if (!selectedDeployments || !deployments || deployments?.length === 0)
      return;

    //deployment_ids: ['aa', 'bb']
    const selDeploymentObjs = deployments.filter(
      (dep) => selectedDeployments.indexOf(dep.value) > -1
    );
    setSelectedValues([...selDeploymentObjs]);
  }, [selectedDeployments, deployments]);

  useEffect(() => {
    loadDeployments();
  }, []);

  const loadDeployments = () => {
    const userProfile = dataServiceContext.getUserProfile();

    if (userProfile.deployments?.length > 0) {
      const upDeployments = isOwnerRole
        ? userProfile.deployments
        : userProfile.deployments.filter((dp) => dp.role === "admin");
      const dataOut = upDeployments.map((itm) => {
        return { ...itm, value: itm.id, label: itm.name };
      });

      setDeployments([...dataOut]);
    }
    setResponse({ code: 200 });
  };

  return <>{content}</>;
});

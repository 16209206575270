import {
  signalSectionBottom,
  commonSection,
  inEnabled,
  outEnabled,
  commonDashboardExtension,
} from "./CommonFields";
import { rebuildSection } from "./IntegrationDefinitionUtils";
import {
  INTEGRATION_TEST_TYPES,
  IntegrationCategories,
} from "./IntegrationConsts";
//--------------------------------------------------------------
// sciencelogic
//--------------------------------------------------------------
export const sciencelogic = {
  common: rebuildSection(commonSection, {
    integration: "sciencelogic",
    category: IntegrationCategories.OBSERVABILITY_DASHBOARD,
    out_event_type: "zebrium_incident,zebrium_monitor_metrics",
    auth_scheme: "basic",
  }),
  signal: {
    hideOnCreate: true,
    top: [
      inEnabled,
      {
        field_name: "tabTitle",
        field_type: "description",
        value:
          "ScienceLogic can be configured to send signals to Zebrium. Zebrium will search for root cause indicators in your logs around the time of the signal. If found, Zebrium will send the results as an event payload to ScienceLogic.",
      },
    ],
    APIConfig: [
      {
        field_name: "sectionTitle",
        field_type: "read-only",
        value: "API Configuration",
      },
      {
        field_name: "username",
        required: true,
        label: "Username",
        db_field_name: "auth_username",
        field_type: "text",
        value: "API Configuration",
      },
      {
        field_name: "password",
        required: true,
        label: "Password",
        db_field_name: "auth_password",
        field_type: "text",
        // }, {
        //     field_name: "deviceId",
        //     required: true,
        //     label: "Device Id",
        //     db_field_name: "resource_id",
        //     field_type: "text"
      },
      {
        field_name: "applianceUrl",
        required: true,
        label: "Appliance URL",
        db_field_name: "base_url",
        field_type: "text",
      },
    ],
    HTTPAuth: [
      {
        field_name: "sectionTitle2",
        field_type: "read-only",
        value: "HTTP Authentication",
      },
      {
        field_name: "usernameIn",
        required: true,
        label: "Username",
        db_field_name: "in_auth_username",
        field_type: "text",
      },
      {
        field_name: "passwordOut",
        required: true,
        label: "Password",
        db_field_name: "in_auth_password",
        field_type: "text",
      },
    ],
    bottom: signalSectionBottom,
    order: ["top", "APIConfig", "HTTPAuth", "bottom"], //ordering of ui sections
  },
  detection: [
    outEnabled,
    {
      field_name: "tabTitle",
      field_type: "description",
      value:
        "Detections occur whenever Zebrium finds a correlated set of root cause indicators in the logs (these form a root cause report summary). Detections are sent as event payloads to ScienceLogic.",
    },
    {
      field_name: "username2",
      required: true,
      label: "Username",
      db_field_name: "auth_username",
      field_type: "text",
    },
    {
      field_name: "password2",
      required: true,
      label: "Password",
      db_field_name: "auth_password",
      field_type: "text",
    },
    {
      field_name: "deviceId2",
      required: true,
      label: "Device Id",
      db_field_name: "resource_id",
      field_type: "text",
    },
    {
      field_name: "applianceUrl2",
      required: true,
      label: "Appliance URL",
      db_field_name: "base_url",
      field_type: "text",
    },
    {
      field_name: "test-integration",
      required: true,
      label: "Create Sample Alert",
      test_type: INTEGRATION_TEST_TYPES.CONNECT_AND_CREATE,
      field_type: "button",
      tooltip: "Create an API key under Organization Settings in ScienceLogic.",
    },
  ],
};

export const sciencelogicDashboardExtension = rebuildSection(
  commonDashboardExtension,
  {
    category: IntegrationCategories.DASHBOARD_EXTENSION,
    integration: "sciencelogic",
    auth_scheme: "none",
    tabTitle:
      "This will generate an Access Token that will be needed when configuring a dashboard integration (12.1 or later) or events integration (12.2 or later) in ScienceLogic SL1.",
  }
);

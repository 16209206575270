import '../styles/globals.scss'
import 'athena-next-ui-lib/dist/bundle.css'

import React, { useState} from "react";
import {AppWrapper,PromptToSendLogs,WebSocketManager,DeploymentChangeDetector} from "athena-next-ui-lib";
import Head from "next/head";
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
// see https://github.com/FortAwesome/react-fontawesome/issues/134 for details
import '@fortawesome/fontawesome-svg-core/styles.css';
// Prevent fontawesome from adding its CSS since we did it manually above:
import {config} from '@fortawesome/fontawesome-svg-core';
import getConfig from "next/config";
import {useRouter} from "next/router";
import {WizardDialogContextProvider} from "/components-biz";
import CustomFonts from "/components-ui/fonts";

config.autoAddCss = false; /* eslint-disable import/first */


const MyApp = ({Component, pageProps}) => {
    const router = useRouter();
    const env = getConfig().publicRuntimeConfig;
    const isSubFolders2Lvl = ["/settings/integrations/"].some(route=>router.pathname.toLowerCase().indexOf(route)>-1);
    let favicon = null;
    const webSocketManager = new WebSocketManager(env);

    if( isSubFolders2Lvl) {
        favicon = "../../images/favicon.png"
    } else {
        const isSubFolder = ["/settings/"].some(route=>router.pathname.toLowerCase().indexOf(route)>-1);
        favicon = isSubFolder ? "../images/favicon.png" : "images/favicon.png";
    }

    return <AppWrapper env={env} router={router} webSocketManager={webSocketManager}>
        <WizardDialogContextProvider>
            <CustomFonts/>
            <Head>
                <link rel="shortcut icon" href={favicon}/>
            </Head>
            <Component {...pageProps} />
            <PromptToSendLogs  router={router} webSocketManager={webSocketManager} zapi_url={env.ZAPI_URL}/>
            <DeploymentChangeDetector webSocketManager={webSocketManager}/>
        </WizardDialogContextProvider>
    </AppWrapper>
}

MyApp.getInitialProps = async (ctx) => { return {}; }

export default MyApp
import React, {
  useContext,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Column,
  Form,
  Row,
  Field,
  FieldTypes,
  DataServiceContext,
  useModalDialog,
  getErrorMessageDisplay,
  PageContext,
} from "athena-next-ui-lib";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const DialogDeployment = forwardRef((props, ref) => {
  const pageContext = useContext(PageContext);
  const dataServiceContext = useContext(DataServiceContext);
  const { openModalDialog, closeModalDialog } = useModalDialog();

  const DEFAULT_GPT_SERVICE = pageContext?.env?.PRODUCT_TYPE === "saas"; //default gpt services is true only for SAAS account
  const canUpdateDeployment =  dataServiceContext.AccessManifest(
    "canUpdate",
    "deployment-mgmt"
  );

  const FormRef = useRef();
  const name = useRef();
  const gptService = useRef();

  const isAdd = props.action === "ADD";
  const gptServiceValues = [
    { label: "Enabled", value: true },
    { label: "Disabled", value: false },
  ];

  useImperativeHandle(ref, () => ({
    showDialog,
  }));

  const deriveGptServiceObj = (booleanValue) => {
    return gptServiceValues.find(
      (gptService) => gptService.value === booleanValue
    );
  };

  const showDialog = (deployment) => {
    let title, buttonLabel;
    if  (canUpdateDeployment ) {
      title =    props.action === "ADD" ? "Add Deployoment Settings" : "Edit Deployment Settings";
      buttonLabel = props.action === "ADD" ? "Add" : "Update";
    } else {
      title = "Deployment Settings" ;
      buttonLabel = "OK";
    }

    const submitCallback = () => {
      if( !canUpdateDeployment ) {
        closeModalDialog();
        return;
      }

      let payload = FormRef.current.getValues();

      if (payload.errors.length > 0) {
        //form contains error(s), throw error
        const err =  { message: getErrorMessageDisplay(payload.errors) };
        throw err;
      }
      delete payload.errors;

      let apiUrl = null;
      if (isAdd) {
        apiUrl = "deployment/create/add";
      } else {
        apiUrl = "deployment/update";
        payload = { ...payload, id: deployment.id };
      }

      return Promise.resolve()
        .then(() => dataServiceContext.fetch(apiUrl, payload))
        .then((deploymentAddCall) => {
          if (deploymentAddCall.response.code === 200) {
            props?.updateCallback?.(true);
          } else {
            throw deploymentAddCall.response;
          }
          return true;
        });
    };

    const fieldProps = {
      labelWidth: "120px",
      labelAlign: "right",
      fieldWidth: "300px",
    };
  
    const gptDesc = (
      <p style={{ textAlign: "left" }}>
        This optional feature uses external API services from OpenAI. Further
        details can be found here:&nbsp;
        <a
          href="https://openai.com/policies/terms-of-use"
          target={"_blank"}
          className={"link"}
        >
          OpenAI Terms of use
        </a>
      </p>
    );

    const modalDialogSettings = {
      title: title,
      icon: null,
      maxWidth: "550px",
      submitLabel: buttonLabel,
      submit: submitCallback,
      content: (
        <Form ref={FormRef} refs={[name, gptService]}>
          <Row>
            <Column width={"600px"}>
              <Field
                {...fieldProps}
                label="Name"
                ref={name}
                required={isAdd}
                type={"text"}
                name={"name"}
                width={"200px"}
                disabled={ !canUpdateDeployment }
                value={deployment ? deployment.name : ""}
              />
            </Column>
          </Row>
          <Row>
            <Column width={"500px"}>
              {canUpdateDeployment && <Field
                {...fieldProps}
                ref={gptService}
                type={FieldTypes.BUTTON_GROUP}
                label="GPT Services"
                name={"gpt3"}
                css={["md", "dark"]}
                values={gptServiceValues}
                value={deriveGptServiceObj(
                  deployment ? !!deployment.gpt3 : DEFAULT_GPT_SERVICE
                )}
              />}
               {!canUpdateDeployment && <Field
                {...fieldProps}
                label="GPT Services"
                type={FieldTypes.DISPLAY_TEXT}
                value={deployment.gpt3 ? "Enabled" : "Disabled"}
              />}
            </Column>
          </Row>
          <Row css={{marginTop:"-3px"}}>
            <Field
              label=" "
              {...fieldProps}
              type={FieldTypes.DISPLAY_TEXT}
              value={gptDesc}
            />
          </Row>
        </Form>
      ),
    };

    return Promise.resolve().then(() => openModalDialog(modalDialogSettings));
  };

  if (isAdd) {
    return (
      <Button onClick={() => showDialog({})} processing={false}>
        <FontAwesomeIcon icon={faPlus} />
        <span>Add Deployment</span>
      </Button>
    );
  } else {
    return null;
  }
});

import {
  commonSection,
  detectionSectionTop,
  inEnabled,
  outEnabled,
  signalSectionBottom,
} from "./CommonFields";
import { rebuildSection } from "./IntegrationDefinitionUtils";
import { IntegrationCategories } from "./IntegrationConsts";

//--------------------------------------------------------------
// webhook
//--------------------------------------------------------------

const fieldProps = { labelWidth: "200px" };
export const webhook = {
  common: rebuildSection(commonSection, {
    category: IntegrationCategories.WEBHOOKS,
    integration: "webhook",
    out_event_type: "zebrium_incident",
    out_alert_on_new_incidents: true,
    auth_scheme: "none",
  }),
  notification: {
    top: [outEnabled],
    webhookConfig: [
      {
        field_name: "webhookURL",
        required: true,
        label: "Webhook URL",
        db_field_name: "out_webhook",
        field_type: "text",
        ...fieldProps,
      },
      {
        field_name: "authMethod",
        required: true,
        label: "Authentication Method",
        db_field_name: "auth_scheme",
        field_type: "button-group",
        ...fieldProps,
      },
      {
        field_name: "authPrefix",
        required: false,
        label: "Authentication Prefix",
        db_field_name: "auth_header_prefix",
        field_type: "text",
        ...fieldProps,
      },
      {
        field_name: "authUsername",
        required: false,
        label: "Username",
        db_field_name: "auth_username",
        field_type: "text",
        ...fieldProps,
      },
      {
        field_name: "authPassword",
        required: false,
        label: "Password",
        db_field_name: "auth_password",
        field_type: "text",
        ...fieldProps,
      },
      {
        field_name: "authToken",
        required: false,
        label: "Token",
        db_field_name: "auth_token",
        field_type: "text",
        ...fieldProps,
      },
      ...detectionSectionTop,
    ],
    order: ["top", "webhookConfig"], //ordering of ui sections
  },
};

export const webhookInbound = {
  common: rebuildSection(commonSection, {
    category: IntegrationCategories.WEBHOOKS,
    integration: "generic",
    out_enabled: false,
    in_enabled: true,
  }),
  signal: {
    top: [inEnabled],
    bottom: signalSectionBottom,
    order: ["top", "bottom"], //ordering of ui sections
  },
};
